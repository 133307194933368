/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   30.10.20 Joel Benford               Cache state in redux store
//    003   10.12.20 Sean Flook         WI38224 Added Street Lookups.
//    004   21.01.21 Sean Flook         WI38232 Changes required for saving the data.
//    005   21.01.21 Joel Benford       WI39069 Add Features controller methods.
//    006   12.03.21 Sean Flook         WI39268 Added GetLastExportDateURL.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    007   27.05.21 Peter Bryden       WI39324 Added GetClosedStreetsWithASDURL & MoveClosedStreetASDsURL.
//    008   07.06.21 Paula Dormer       WI39308 Added the CloseSelectedASDRecords
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

import { GetApiSite } from "./AAConfig_Api"

const getHeaderInfo = (userToken) => {
  return {
    "Content-Type": "text/plain",
    Authorization: "Bearer " + userToken,
  };
};


export const GetOperatorsURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/GetOperators");

  return { 
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetOperatorUsersURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/GetOperatorUsers");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetOperatorTrunkRoadsURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/GetOperatorTrunkRoads");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUserIdURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/GetUserId");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetCanOperatorEditASDDataURL = async (userToken) => {
  const api_url = await GetApiSite("/api/street/CanOperatorEditAsd");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetSearchStreetURL = async (userToken) => {
  const api_url = await GetApiSite("/api/StreetSearch");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetStreetFromUSRN = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/GetStreetByUSRN");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

//SF WI38224++
export const GetLocalities_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/StreetLookup/GetLocalities");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetTowns_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/StreetLookup/GetTowns");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetAdministrativeAreas_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/StreetLookup/GetAdministrativeAreas");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetStreetRefs_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/StreetLookup/GetStreetRefs");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetOSGLanguages_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/StreetLookup/GetOSGLanguages");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};
//SF WI38224--

export const GetOSGRoadStatusCodes_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/ASDLookup/GetOSGRoadStatusCodes");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetOSGSwaOrgRefs_URL = async (userToken) => {
  const api_url = await GetApiSite("/api/ASDLookup/GetOSGSwaOrgRefs");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetOSGReinstatementTypeCodes_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/ASDLookup/GetOSGReinstatementTypeCodes");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetOSGSpecialDesignationCodes_URL = async (userToken) => {

  const api_url = await GetApiSite("/api/ASDLookup/GetOSGSpecialDesigCodes");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
};

export const GetAddMaintenanceResponsibilitiesURL = async (userToken) =>{
  const api_url = await GetApiSite("/api/Street/AddMaintenanceResponsibilities");

  return {
    url: api_url,
    type: "POST",
    headers: getHeaderInfo(userToken)
  };
}

export const GetAddReinstatementCategoriesURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/AddReinstatementCategories");

  return {
    url: api_url,
    type: "POST",
    headers: getHeaderInfo(userToken)
  };
}

export const GetAddSpecialDesignationsURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/AddSpecialDesignations");

  return {
    url: api_url,
    type: "POST",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUpdateMaintenanceResponsibilitiesURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/UpdateMaintenanceResponsibilities");

  return {
    url: api_url,
    type: "PUT",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUpdateReinstatementCategoriesURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/UpdateReinstatementCategories");

  return {
    url: api_url,
    type: "PUT",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUpdateSpecialDesignationsURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/UpdateSpecialDesignations");

  return {
    url: api_url,
    type: "PUT",
    headers: getHeaderInfo(userToken)
  };
}

export const GetDeleteMaintenanceResponsibilitiesURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/DeleteMaintenanceResponsibilities");

  return {
    url: api_url,
    type: "DELETE",
    headers: getHeaderInfo(userToken)
  };
}

export const GetDeleteReinstatementCategoriesURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Street/DeleteReinstatementCategories");

  return {
    url: api_url,
    type: "DELETE",
    headers: getHeaderInfo(userToken)
  };
}

export const GetDeleteSpecialDesignationsURL = async (userToken) =>  {
  const api_url = await GetApiSite("/api/Street/DeleteSpecialDesignations");

  return {
    url: api_url,
    type: "DELETE",
    headers: getHeaderInfo(userToken)
  };
}

export const GetAddUserURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/AddUser");

  return {
    url: api_url,
    type: "POST",
    headers: getHeaderInfo(userToken)
  };
}

export const GetAddTrunkRoadUsrnURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/AddTrunkRoadUsrn");

  return {
    url: api_url,
    type: "POST",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUpdateOperatorURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/UpdateOperator");

  return {
    url: api_url,
    type: "PUT",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUpdateUserURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/UpdateUser");

  return {
    url: api_url,
    type: "PUT",
    headers: getHeaderInfo(userToken)
  };
}

export const GetUpdateTrunkRoadUsrnURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/UpdateTrunkRoadUsrn");

  return {
    url: api_url,
    type: "PUT",
    headers: getHeaderInfo(userToken)
  };
}

export const GetDeleteUserURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/DeleteUser");

  return {
    url: api_url,
    type: "DELETE",
    headers: getHeaderInfo(userToken)
  };
}

export const GetDeleteOperatorUsersURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/DeleteOperatorUsers");

  return {
    url: api_url,
    type: "DELETE",
    headers: getHeaderInfo(userToken)
  };
}

export const GetDeleteTrunkRoadUsrnURL = async (userToken) => {
  const api_url = await GetApiSite("/api/User/DeleteTrunkRoadUsrn");

  return {
    url: api_url,
    type: "DELETE",
    headers: getHeaderInfo(userToken)
  };
}

//JB WI39069++
export const GetImportExportHistoriesURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Features/GetImportExportHistories");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetLastExportDateURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Features/GetLastExportDate");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}

export const GetLookupsLastChangedURL = async (userToken) => {
  const api_url = await GetApiSite("/api/Features/GetLookupsLastChanged");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}
//JB WI39069--

export const GetClosedStreetsWithASDURL = async (userToken) => {
  const api_url = await GetApiSite("/api/street/GetClosedStreetsWithASD");

  return {
    url: api_url,
    type: "GET",
    headers: getHeaderInfo(userToken)
  };
}


export const MoveClosedStreetASDsURL = async (userToken) => {
  const api_url = await GetApiSite("/api/street/MoveASDRecordsToDifferentStreet");

  return {
    url: api_url,
    type: "POST",
    headers: getHeaderInfo(userToken)
    };
}

export const CloseSelectedASDRecordsURL = async (userToken) => {
    const api_url = await GetApiSite("/api/street/CloseSelectedASDRecords");

    return {
        url: api_url,
        type: "POST",
        headers: getHeaderInfo(userToken)
    };
}

/**************************************************************************************************
//
//  Description: Main container page for gazetteer details
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   15.01.21 Sean Flook         WI39041 Changes required for creating new records.
//    003   18.01.21 Peter Bryden               Added changes to show graphic for asd / esu
//    004   19.01.21 Peter Bryden               Updated so new records always start with full road geometry
//    005   19.01.21 Sean Flook                 Corrected spelling of Gazetteer.
//    006   20.01.21 Sean Flook         WI39037 Added ability to select a street from the map.
//    007   20.01.21 Sean Flook         WI39037 Fix for when only 1 street returned in handleFromCoordSearch.
//    008   25.01.21 Sean Flook         WI38232 Initial check-in for changes required for saving ASD records.
//    009   25.01.21 Sean Flook         WI38232 Moved the fetch to the correct place.
//    010   25.01.21 Sean Flook         WI38232 Corrected fetch call.
//    011   26.01.21 Sean Flook         WI38232 Changes required to limit which streets a user can edit.
//    012   27.01.21 Sean Flook         WI38232 Only use open ESUs when getting the Whole Road geometry.
//                                              Also pass through the type of extra graphic being passed through to the map.
//    013   29.01.21 Peter Bryden       WI38232 cleaned up code and handled save back to forms
//    014   01.02.21 Sean Flook         WI38232 Added basic validation of the data before saving.
//    015   02.02.21 Sean Flook         WI38232 Correctly allow for a Reinstatement Category of 0.
//    016   02.02.21 Sean Flook         WI38232 If we get an error back from the save API display it to the user.
//    017   02.02.21 Sean Flook         WI38232 Only allow editing of geometry if part of road selected.
//    018   03.02.21 Sean Flook         WI38232 Modifications to the display of the extra graphic layer.
//    019   04.02.21 Sean Flook         WI38232 Split the extra graphic into 2 layers (highlight and edit) to prevent editing of ESUs and Whole Road ASD records.
//    020   05.02.21 Sean Flook         WI38232 Use the pkId in handleFormChangeOnMap to ensure we are dealing with the correct record.
//    021   08.02.21 Sean Flook         WI38232 Pass the validation errors back to the form so they can be displayed there.  
//    022   09.02.21 Sean Flook         WI38232 Fixed displaying ESUs in map.
//    023   09.02.21 Peter Bryden       WI38668 Change user_id parameter to operator_id from logged on user
//    024   09.02.21 Peter Bryden       WI38668 Update to fix esu problem introduced during last merge
//    025   10.02.21 Sean Flook         WI38232 Added check for maximum allowable length for text fields.
//    026   10.02.21 Sean Flook         WI38232 Corrected handleFormChangeOnMap so editGraphic.paths is correctly set.
//    027   10.02.21 Sean Flook         WI38232 Pass through the operator id to the GazetteerPageForm.
//    028   10.02.21 Sean Flook         WI38232 Use the correct data when getting the special designation geometry.
//    029   10.02.21 Sean Flook         WI38232 Change to prevent browser scrollbar from always appearing.
//    030   11.02.21 Sean Flook         WI38232 Corrected logic when checking for long strings in specific location.
//    031   12.02.21 Sean Flook         WI38232 Tweaked the height.
//    032   16.02.21 Sean Flook         WI39167 Added a delete confirmation dialog.
//    033   16.02.21 Sean Flook         WI39149 Raise validation error when saving if the geometry intersects itself.
//    034   17.02.21 Sean Flook         WI39175 Changed how we handle errors in handleSaveASD from the fetch.
//    035   18.02.21 Sean Flook         WI39168 Increased height.
//    036   23.02.21 Sean Flook         WI39198 Changed the line colours as required.
//    037   24.02.21 Sean Flook         WI39209 Check for start date in the future.
//    038   26.02.21 Peter Bryden       WI39153 pass geometry ref to associatedstreetdatafrom
//    039   03.03.21 Sean Flook         WI39183 Added a className to the gazetteerPage div so the height can be set in the CSS.
//    040   10.03.21 Peter Bryden               Added in auto logout if the user call comes back with a 401 which would indicate a token expiry or token refresh failure
//    041   11.03.21 Sean Flook         WI39262 Differentiate between open and closed streets in the search dialog.
//    042   16.03.21 Sean Flook         WI39278 Check we actually have a geometry when saving.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    043   07.06.21 Paula Dormer       WI39308 Added some //nodefault clauses and changed single = 
//                                              for == to remove the unnecessary warnings
//    044   09.06.21 Paula Dormer       WI39308 Removed the extra = from the above change.
//    045   18.06.21 Peter Bryden       WI39514 Added lastupdated date for new asds
//    046   01.07.21 Paula Dormer       WI39308 Add the Usrn and streetname below the header bar.
//#endregion Version 1.0.1.0 changes
//#region Version 1.1.0.0 changes
//    047   13.09.22 Peter Bryden               Added in usercontext current operator parameter
//#endregion Version 1.1.0.0 changes

//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, useState, useRef, useContext } from "react";
import PropTypes from 'prop-types';
import { DialogContent, DialogActions, Grid, Typography } from "@material-ui/core";

import GazetteerPageForm from "./../components/gazetteerpage/gazetteerpageform"
import AAEsriMapWithGraphic from "./../components/AAEsriMapWithGraphic";

import { useParams } from "react-router-dom";

import {
  GetStreetFromUSRN,
  GetSearchStreetURL,
  GetCanOperatorEditASDDataURL,
} from '../configuration/AAConfig'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';

import history from "../history";
import moment from 'moment';

import { UserContext } from "../contexts/userContext";

import { ConfirmationServiceProvider } from "./GazetteerPageConfirmation";


/* #endregion imports */

function SearchDialog(props) {
  const { onClose, selectedValue, selectedName, open, foundStreets } = props;

  const openStreet = { color: 'black' };
  const closedStreet = { color: 'grey' };

  const handleClose = () => {
    onClose(selectedValue, selectedName);
  };

  const handleListItemClick = (value, name) => {
    onClose(value, name);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id="search_street_dialog">Select Street</DialogTitle>
      <List>
        {foundStreets && foundStreets.map((street) => (
          <ListItem button onClick={() => handleListItemClick(street.usrn, street.descriptor)} key={street.usrn}>
            <ListItemText primary={street.usrnNavigation.endDate ? street.descriptor : <strong>{street.descriptor}</strong>} primaryTypographyProps={{ style: street.usrnNavigation.endDate ? closedStreet : openStreet }} />
          </ListItem>
        ))}

        <ListItem autoFocus button onClick={() => handleListItemClick(-1)}>
          <ListItemAvatar>
            <Avatar>
              <CancelIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Cancel" />
        </ListItem>
      </List>
    </Dialog>
  );
}

SearchDialog.propType = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  selectedName: PropTypes.string.isRequired,
  foundStreets: PropTypes.Array,
};

function ConfirmationDialog(props) {
  const { onClose, open, currentform, ...other } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  const getFormText = () => {
    let returnText = "Are you sure you wish to delete this record?";

    if (currentform) {
      switch (currentform) {
        case "MaintenanceResponsibility":
          returnText = "Are you sure you wish to delete this Maintenance Responsibility record?";
          break;
        case "ReinstatementCategory":
          returnText = "Are you sure you wish to delete this Reinstatement Category record?";
          break;
        case "SpecialDesignation":
          returnText = "Are you sure you wish to delete this Special Designation record?";
              break;
           // no default
      }
    }

    return returnText;
  };

  return (
    <Dialog disableBackdropClick disableExcapeKeyDown maxWidth="xs" open={open} aria-labelledby="confirmation-dialog-title" {...other}>
      <DialogTitle id="confirmation-dialog-title">Delete Record?</DialogTitle>
      <DialogContent>
        <Typography variant="body2">{getFormText()}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const GazetteerPage = () => {
  let { usrn } = useParams();

  const { currentOperator } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [apiURLs, setApiURLs] = useState(null);

  const [data, setData] = useState();

  const [asdData, setASDData] = useState({ maintenanceResponsibilities: [], specialDesignations: [], reinstatementCatergories: [] });

  const [dataUsrn, setDataUsrn] = useState(usrn);
  const [dataStreetName, setDataStreetName] = useState();  //PD WI39308++

  const [overlayGraphic, setOverlayGraphic] = useState({ highlightLayer: null, editLayer: null });
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(-1);
  const [selectedName, setSelectedName] = useState("");
  const [foundStreets, setFoundStreets] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [currentform, setCurrentForm] = useState();
  const [asdEditable, setAsdEditable] = useState(false);

  const asdGeometry = useRef(null);
  const asdGeometryIntersects = useRef(false);
  const deleteURL = useRef(null);
  const deleteFormName = useRef("");
  const deletePkId = useRef(-1);

  const updateMaintenanceResponsibilities = (newData) => {
    const newASDData = { maintenanceResponsibilities: newData, specialDesignations: asdData.specialDesignations, reinstatementCatergories: asdData.reinstatementCatergories };
    setASDData(newASDData);
  }

  const updateReinstatementCatergories = (newData) => {
    const newASDData = { maintenanceResponsibilities: asdData.maintenanceResponsibilities, specialDesignations: asdData.specialDesignations, reinstatementCatergories: newData };
    setASDData(newASDData);
  }

  const updateSpecialDesignations = (newData) => {
    const newASDData = { maintenanceResponsibilities: asdData.maintenanceResponsibilities, specialDesignations: newData, reinstatementCatergories: asdData.reinstatementCatergories };
    setASDData(newASDData);
  }

  const updateASDData = (actionType, actionData, pkId, formName) => {

    function updateASDArray(arrIn, action, data) {
      let asdArray = arrIn ? [...arrIn] : [];
      switch (action) {
        case "POST": asdArray.push(actionData);
          break;
        case "PUT": const arrIdx = asdArray.findIndex((elem) => elem.pkId == pkId);
          if (arrIdx >= 0) {
            actionData.lastUpdated = new Date();
            asdArray[arrIdx] = actionData;
          }
          break;
        case "DELETE": asdArray = asdArray.filter((x) => x.pkId !== pkId);
              break;
        default: break; //no default
      }
      return asdArray;
    }

    if (formName) {

      switch (formName) {
        case "MaintenanceResponsibility":
          const newMaintenanceResponsibilites = updateASDArray(asdData.maintenanceResponsibilities, actionType, actionData);
          updateMaintenanceResponsibilities(newMaintenanceResponsibilites);
          break;
        case "ReinstatementCategory":
          const newReinstatementCatergories = updateASDArray(asdData.reinstatementCatergories, actionType, actionData);
          updateReinstatementCatergories(newReinstatementCatergories);
          break;
        case "SpecialDesignation":
          const newSpecialDesignations = updateASDArray(asdData.specialDesignations, actionType, actionData);
          updateSpecialDesignations(newSpecialDesignations);
              break;
        //no default
      }
    }

  };

  const wholeRoadWktGeometry = () => {

    if (data) {
      // Only use open ESUs
      const geometryData = Array.from(data.streetCrossRefs.filter(xref => xref.esuNavigation.state !== 4), x => x.esuNavigation.wktGeometry);
      return geometryData;
    }

    return null;
  }

  const wholeAsdWkt = () => {
    const wholeRoadWkt = wholeRoadWktGeometry();

    if (wholeRoadWkt) {
      if (wholeRoadWkt.length > 1)
        return "MULTILINESTRING (" + wholeRoadWkt.join(", ").replace(/LINESTRING /gi, "") + ")";
      else
        return wholeRoadWkt[0];
    }

    return null;
  }

  /*HANDLERS*/
  /* show overlay for map for ASD / ESU otherwise clear off current overlay graphic */
  const handleFormChangeOnMap = (typeName, pkId, isWholeRoad) => {

    const editStyle = { colour: [117, 112, 179], width: 5, style: "solid" };
    const asdHighlightStyle = { colour: [117, 112, 179], width: 5, style: "solid" };
    const esuHighlightStyle = { colour: [27, 158, 119], width: 5, style: "solid" };

    setCurrentForm(typeName);
    let highlightStyle = asdHighlightStyle;
    let graphicData = { dataIdx: -1, wktPaths: null, graphicStyle: highlightStyle, displayType: "WholeASD" };

    const SetupIdxAndWktData = (asdArray, pkId, isWholeRoad) => {
      const idx = asdArray ? asdArray.findIndex((elem) => elem.pkId == pkId) : -1;
      const wkt = (asdArray && idx >= 0 && !isWholeRoad) ? asdArray[idx].wktGeometry : wholeRoadWktGeometry();
      const display = (isWholeRoad) ? "WholeASD" : "PartASD";
      const style = (isWholeRoad) ? highlightStyle : editStyle;
      return { dataIdx: idx, wktPaths: wkt, displayType: display, graphicStyle: style };
    }

    if (data) {
      switch (typeName) {
        case "MaintenanceResponsibility":
          graphicData = SetupIdxAndWktData(asdData.maintenanceResponsibilities, pkId, isWholeRoad);
          break;
        case "ReinstatementCategory":
          graphicData = SetupIdxAndWktData(asdData.reinstatementCatergories, pkId, isWholeRoad);
          break;
        case "SpecialDesignation":
          graphicData = SetupIdxAndWktData(asdData.specialDesignations, pkId, isWholeRoad);
          break;
        case "Esu":
          graphicData.dataIdx = data.streetCrossRefs ? data.streetCrossRefs.findIndex((elem) => elem.esuId == pkId) : -1;
          graphicData.wktPaths = (data.streetCrossRefs && graphicData.dataIdx >= 0) ? data.streetCrossRefs[graphicData.dataIdx].esuNavigation.wktGeometry : null;
          graphicData.displayType = "ESU";
          highlightStyle = esuHighlightStyle;
              break;
        //no default
      }
    }

    handleGraphicChange(graphicData.wktPaths, false);

    const highlightGraphic = { paths: (graphicData.graphicStyle === editStyle ? null : graphicData.wktPaths), colour: highlightStyle.colour, width: highlightStyle.width, style: highlightStyle.style, type: graphicData.displayType };
    const editGraphic = { paths: (graphicData.graphicStyle === editStyle ? graphicData.wktPaths : null), colour: editStyle.colour, width: editStyle.width, style: editStyle.style, type: graphicData.displayType };

    setOverlayGraphic({ highlightLayer: highlightGraphic, editLayer: editGraphic });
  }

  const handleFromCoordSearch = (searchCoords) => {
    const searchApiDetails = apiURLs && apiURLs.SearchApiDetails;
    if (searchApiDetails && searchCoords && searchCoords.length > 0) {
      fetch(`${searchApiDetails.url}?description=${searchCoords}&maxresults=50`)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.length > 0) {
              if (result.length > 1) {
                setFoundStreets(result);
                setOpen(true);
              } else {
                const usrn = result[0].usrn;
                const streetName = result[0].descriptor;
                handleClose(usrn, streetName);
              }
            } else {
              console.log("No streets found at " + searchCoords);
            }
            //items: result.items
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.error(error);
          }
        );
    }
    else {
      console.log("No Search URL details");
    }
  }

  const handleAsdDelete = (asdDeleteURL, formName, pkId) => {
    if (pkId && pkId > 0) {
      deleteURL.current = asdDeleteURL;
      deleteFormName.current = formName;
      deletePkId.current = pkId;

      setOpenConfirmation(true);
    }
  }

  const handleClose = (usrnValue, streetName) => {
    setOpen(false);
    setSelectedValue(usrnValue);
    setSelectedName(streetName);
    if (usrnValue && usrnValue > 0) {
      history.push(`/gazetteer/${usrnValue}`, streetName)
    }
  };

  const handleCloseConfirmation = (deleteConfirmed) => {
    setOpenConfirmation(false);

    //console.log("DEBUG handleCloseConfirmation", deleteConfirmed, deletePkId.current, deleteURL.current);

    if (deleteConfirmed && deletePkId.current && deletePkId.current > 0) {
      setCurrentForm(deleteFormName.current);
      const deleteData = [deletePkId.current];

      if (deleteURL.current.length > 0) {
        fetch(`${deleteURL.current}/${currentOperator}`, {
          method: "DELETE",
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(deleteData),
        })
          .then(res => (res.ok ? res : Promise.reject(res)))
          //.then(res => res.json())
          .then(
            (result) => {
              updateASDData("DELETE", null, deletePkId.current, deleteFormName.current);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              console.log("DEBUG handleAsdDelete Error", error);
            }
          );
      }
    }
  };

  const handleGraphicChange = (geometryStrings, geometryIntersects) => {
    asdGeometry.current = geometryStrings;
    asdGeometryIntersects.current = geometryIntersects;
  };

  async function handleSaveASD(saveURL, saveMethod, savedASD, formName) {

    let validationErrors = [];
    const today = new Date();

    switch (formName) {
      case "MaintenanceResponsibility":
        if (!savedASD.streetStatus)
          validationErrors.push({ key: "streetStatus", error: "Street status is a required field." });
        break;

      case "ReinstatementCategory":
        if (!savedASD.streetReinstatementCategory && savedASD.streetReinstatementCategory !== 0)
          validationErrors.push({ key: "streetReinstatementCategory", error: "Category is a required field." });
        break;

      case "SpecialDesignation":
        if (!savedASD.specialDesignationCode)
          validationErrors.push({ key: "specialDesignationCode", error: "Designation is a required field." });
            break;

       //no default
    }

    if (!savedASD.custodianCode)
      validationErrors.push({ key: "custodianCode", error: "Custodian is a required field." });

    switch (formName) {
      case "MaintenanceResponsibility":
        if (!savedASD.maintainingAuthorityCode)
          validationErrors.push({ key: "maintainingAuthorityCode", error: "Maintaining Authority is a required field." });
        break;

      case "ReinstatementCategory":
        if (!savedASD.reinstatementAuthorityCode)
          validationErrors.push({ key: "reinstatementAuthorityCode", error: "Reinstatement Authority is a required field." });
        break;

      case "SpecialDesignation":
        if (!savedASD.authorityCode)
          validationErrors.push({ key: "authorityCode", error: "Authority is a required field." });
            break;

      //no default
    }

    if (!savedASD.startDate)
      validationErrors.push({ key: "startDate", error: "Start Date is a required field." });
    else if (savedASD.startDate > today)
      validationErrors.push({ key: "startDate", error: "Start Date cannot be in the future." });

    if (formName === "SpecialDesignation") {
      if (!savedASD.description || savedASD.description.length === 0)
        validationErrors.push({ key: "description", error: "Description is a required field." });
      else if (savedASD.description && savedASD.description.length > 255)
        validationErrors.push({ key: "description", error: "Maximum allowable number of characters is 255." });
    }

    if (savedASD.state === 2 && (!savedASD.endDate || moment(savedASD.endDate).format("YYYY MMMM DD") <= moment(savedASD.startDate).format("YYYY MMMM DD"))) {
      validationErrors.push({ key: "endDate", error: "When State is 2, then End Date must be present and greater than Start Date." });
    }

    if (!savedASD.wholeRoad) {
      if (!savedASD.specificLocation || savedASD.specificLocation.length === 0)
        validationErrors.push({ key: "specificLocation", error: "When WHOLE ROAD is false then Specific Location must not be null." });
      else if (savedASD.specificLocation && savedASD.specificLocation.length > 250)
        validationErrors.push({ key: "specificLocation", error: "Maximum allowable number of characters is 250." });
    }

    const wholeWkt = wholeAsdWkt();
    
    if ((!savedASD.wholeRoad && (!asdGeometry.current.includes("LINESTRING") || !asdGeometry.current.includes(","))) || (savedASD.wholeRoad && !wholeWkt))
      validationErrors.push({ key: "wholeRoad", error: "Geometry is a required field." });

    if (wholeWkt && !savedASD.wholeRoad && asdGeometry.current === wholeWkt)
      validationErrors.push({ key: "wholeRoad", error: "When WHOLE ROAD is false then the geometry must not be the same as the street geometry." });

    if (asdGeometryIntersects.current)
      validationErrors.push({ key: "wholeRoad", error: "The geometry intersects itself." });

    if (validationErrors.length === 0) {
      // If the record is marked as Whole Road ensure the geometry is the whole road as well.
      if (savedASD.wholeRoad)
        savedASD.wktGeometry = wholeWkt;
      else
        savedASD.wktGeometry = asdGeometry.current;
      const url = `${saveURL}/${currentOperator}`;
      const asdDataArray = [savedASD];
      const fetchData = JSON.stringify(asdDataArray);

      await fetch(url, {
        method: saveMethod,
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body: fetchData
      })
        .then(res => (res.ok ? res : res.text().then(text => { throw new Error(text) })))
        .then(res => (saveMethod === "POST") ? res.json() : res)
        .then(
          (result) => {
            if (saveMethod === "POST")
              updateASDData(saveMethod, result[0], savedASD.pkId, currentform);
            else
              updateASDData(saveMethod, savedASD, savedASD.pkId, currentform);
          }
        )
        .catch(errorMessage => {
          validationErrors.push({ key: errorMessage.toString().includes("USRN when combined with CUSTODIAN_CODE and") ? "custodianCode" : "dbError", error: errorMessage.toString() });
          console.log("ERROR SaveForm", errorMessage, validationErrors);
        });
    }

    return validationErrors;
  }

  /* HOOKS*/
  useEffect(() => {

    async function SetUpApi() {
      if (!apiURLs) {
        const canEditUrl = await GetCanOperatorEditASDDataURL();
        const usrnUrl = await GetStreetFromUSRN();
        const searchUrl = await GetSearchStreetURL();

        setApiURLs({ canEditASD: canEditUrl, usrnApiDetails: usrnUrl, SearchApiDetails: searchUrl });
      }
    }

    async function CanOperatorEditASD() {
      if (usrn && currentOperator) {
        if (apiURLs && apiURLs.canEditASD != null) {
          const canEditASD = apiURLs.canEditASD;
          console.log("fetching CanUserEditASDData", dataUsrn, `${canEditASD.url}/${currentOperator}/${usrn}`);
          fetch(`${canEditASD.url}/${currentOperator}/${usrn}`)
            .then(res => (res.ok ? res : Promise.reject(res)))
            .then(res => res.json())
            .then(
              (result) => {
                setAsdEditable(result);

              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                console.log("Cannot determine if user can edit ASD data = ", error);
              }
            );
        } else {
          console.log("canEditASD is null");
        }
      }
    }

    async function SetUpStreetData() {
      if (!data || (usrn != dataUsrn)) {
        if (apiURLs && apiURLs.usrnApiDetails != null) {
          const usrnApiDetails = apiURLs.usrnApiDetails;
          console.log("fetching ", dataUsrn, `${usrnApiDetails.url}/${usrn}`);
          setLoading(true);
          fetch(`${usrnApiDetails.url}/${usrn}`)
            .then(res => (res.ok ? res : Promise.reject(res)))
            .then(res => res.json())
            .then(
              (result) => {
                console.log("Setting gazetteer data", result);
                setData(result);
                setDataStreetName((result.streetDescriptors) ? result.streetDescriptors[0].descriptor : ""); //PD WI39308++
                setASDData({
                  maintenanceResponsibilities: result.maintenanceResponsibilities,
                  reinstatementCatergories: result.reinstatementCatergories,
                  specialDesignations: result.specialDesignations
                });
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                console.log("gazetteer fetch error = ", error);
              }
            ).then(() => {

              setDataUsrn(usrn);
              setLoading(false);
            });
        }
        else {
          console.log("usrnApiDetails is null");
        }
      }
    }

    if (!apiURLs)
      SetUpApi();

    SetUpStreetData();

    CanOperatorEditASD();

    return () => {
      if (asdGeometry.current) {
        asdGeometry.current = null;
      }
    };

  }, [asdData, usrn, apiURLs, overlayGraphic]);


  return data && !loading ? (
    <ConfirmationServiceProvider>
      <div className="gazetteer-page" >    
        <Grid container justifyContent="flex-start"  spacing={0}>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start" spacing={0}>
              <Grid item xs={4}>
                <Typography variant="subtitle2" className="displayStreet" >{dataUsrn + " - " + dataStreetName}</Typography> 
                <GazetteerPageForm
                  geometry={asdGeometry}
                  asdData={asdData}
                  streetdata={data}
                  isAsdEditable={asdEditable}
                  operatorId={currentOperator}
                  handleChange={handleFormChangeOnMap}
                  handleSave={handleSaveASD}
                  handleDelete={handleAsdDelete} />
              </Grid>
              <Grid item xs={8}>
                <AAEsriMapWithGraphic isAsdEditable={asdEditable} backgroundgraphic={{ paths: wholeRoadWktGeometry() }} extragraphic={overlayGraphic} handleCoordSearch={handleFromCoordSearch} handleGeometryChange={handleGraphicChange} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          <SearchDialog selectedValue={selectedValue} selectedName={selectedName} open={open} onClose={handleClose} foundStreets={foundStreets} />
        </div>
        <div>
          <ConfirmationDialog open={openConfirmation} onClose={handleCloseConfirmation} currentform={deleteFormName.current} />
        </div>
      </div>
    </ConfirmationServiceProvider>
  ) : "Loading Gazetteer Page";
};

GazetteerPage.propTypes = {
  //classes: PropTypes.object.isRequired,
};

export default GazetteerPage;

/* #region header */
/**************************************************************************************************
//
//  Description: Special Designation DropDown, refactored from asd forms
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   27.01.21 Peter Bryden               Initial Revision.
//    002   08.02.21 Sean Flook         WI38232 Set requiredField.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import AAAvatarSelect from "../AAAvatarSelect";

/* #endregion imports */

const ASDSpecialDesignationTypeDropDown = ({ type_data, selectedValue, changeHandler, dataRequired }) => {

  const dataKey = "streetSpecialDesigCode";
  const dataText = "streetSpecialDesigText";
  const handleChange = (event) => { if (changeHandler != null) changeHandler(event.value[dataKey]); }

  const [data] = useState(type_data);
  const [selectedKey] = useState(selectedValue);

  return (
    <AAAvatarSelect
      data={data}
      avatarField={dataKey}
      textField={dataText}
      keyField={dataKey}
      placeholder="choose type"
      selectedKey={selectedKey}
      colour="brown"
      requiredField={dataRequired}
      onChange={handleChange}
    />
  );
}

export default ASDSpecialDesignationTypeDropDown;
/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//
//--------------------------------------------------------------------------------------------------
/**************************************************************************************************/

import { GetSecurityApiSite } from "./AAConfig_Api"
  
  
  export async function PostUserLogin() {
    const url = await GetSecurityApiSite("/api/Authority/Login");
  
    return {
      url: url,
      type: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
  
  export async function GetWhoAmIUrl(userToken) {
    const url = await GetSecurityApiSite("/api/Authority/WhoAmI");
  
    return {
      url: url,
      type: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
    };
  }
  
  export async function GetUser(userToken) {
    const url = await GetSecurityApiSite("/api/User");
  
    return {
      url: url,
      type: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
    };
  }
  
  export async function UpdateMyPassword(userToken) {
    const url = await GetSecurityApiSite("/api/Authority/UpdateMyPassword");
  
    return {
      url: url,
      type: "PUT",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
    };
  }
  
  
  
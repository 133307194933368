
export function ObjectComparison(object1, object2, keysToIgnore) {

  //return false if either or both are null
  if (!object1 || !object2) {
    console.log("ObjectComparrison null object found", object1, object2);
  }

  const keys_object1 = Object.keys(object1);
  const keys_object2 = Object.keys(object2);

  const keys1 = (keys_object1 && keysToIgnore) ? keys_object1.filter(x => !keysToIgnore.includes(x)) : keys_object1;
  const keys2 = (keys_object2 && keysToIgnore) ? keys_object2.filter(x => !keysToIgnore.includes(x)) : keys_object2;

  if (keys1.length !== keys2.length) {
    console.log("ObjectComparrison key length fail", keys1.length, keys2.length, keys1, keys2)
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      console.log("ObjectComparrison difference fail", key, object1[key], object2[key]);
      return false;
    }
  }

  return true;
}
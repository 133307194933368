/**************************************************************************************************
//
//  Description:  API wrapper
//
//  Copyright:    © 2020-21 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue#  Description
//#region Version 1.0.0.0 changes
//    001   22.02.21 Joel Benford        WI39069 Initial Revision.
//    002   12.03.21 Sean Flook          WI39628 Added getLastExportDateAsync.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    004   01.06.21 Paula Dormer       WI39308 With Pete's help, return the error message and add 
//                                              error trapping in case the result can't be formatted 
//                                              to Json.
/    004   04.06.21 Peter Bryden        WI39628 Update fetchDataAsync to allow for text error return
//#endregion Version 1.0.1.0 changes
//#region Version 1.1.0.0 changes
//    00   13.09.22 Peter Bryden               Added in Operators 
//#endregion Version 1.1.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

//#region imports
import { GetOSGSwaOrgRefs_URL, GetLocalities_URL, GetTowns_URL, GetAdministrativeAreas_URL, GetStreetRefs_URL, GetOSGLanguages_URL, GetLookupsLastChangedURL, GetLastExportDateURL, GetOperatorsURL } from '../configuration/AAConfig';
//#endregion imports

// lookups methods
export const getLastExportDateAsync = async (userToken) => await fetchDataAsync(await GetLastExportDateURL(userToken));
export const getLookupsLastChangedAsync = async (userToken) => await fetchDataAsync(await GetLookupsLastChangedURL(userToken));
export const getAdministrativeAreasAsync = async (userToken) => await fetchDataAsync(await GetAdministrativeAreas_URL(userToken));
export const getLocalitiesAsync = async (userToken) => await fetchDataAsync(await GetLocalities_URL(userToken));
export const getOSGLanguagesAsync = async (userToken) => await fetchDataAsync(await GetOSGLanguages_URL(userToken));
export const getOSGSwaOrgRefsAsync = async (userToken) => await fetchDataAsync(await GetOSGSwaOrgRefs_URL(userToken));
export const getStreetRefsAsync = async (userToken) => await fetchDataAsync(await GetStreetRefs_URL(userToken));
export const getTownsAsync = async (userToken) => await fetchDataAsync(await GetTowns_URL(userToken));
export const getOperatorsAsync = async (userToken) => await fetchDataAsync(await GetOperatorsURL(userToken));



//Does the actual API get
//urlDetails is a data structure returned by configuration/AAConfig.js methods like GetLocalities_URL()
//e.g. { "url": 'https://localhost:44321//api/StreetLookup/GetLocalities', "type": "GET", "headers": [{ "Content-Type": "text/plain" }] }
const fetchDataAsync = async (urlDetails) => {
    return await fetch(`${urlDetails.url}`, {
        headers: urlDetails.headers,
        crossDomain: true,
        method: urlDetails.type,
        })
        .then(response => response.text())
        .then(text => {
            try {
                const jsonData = JSON.parse(text);
                return jsonData;
            }
            catch (err) {
                return text
            }
        })
        .catch(e => { return e; });
};

/**************************************************************************************************
//
//  Description: Maintenance page 
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.1.0 changes
//    001   27.05.21 Peter Bryden                Initial Revision.
//    002   01.06.21 Peter Bryden        WI39324 Change list component to table component so not to use kendo licening 
//#endregion Version 1.0.1.0 changes
//#region Version 1.1.0.0 changes
//    003   13.09.22 Peter Bryden               Added in user context token and operator variables
//#endregion Version 1.1.0.0 changes

//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";

import { MaintenanceStreetTableView } from "../components/maintenancepage/MaintenanceStreetTableView"

import { useParams,Redirect } from "react-router-dom";

import { UserContext } from "../contexts/userContext";

import {
  GetStreetFromUSRN,
  GetSearchStreetURL,
  GetCanOperatorEditASDDataURL,
  GetClosedStreetsWithASDURL
} from '../configuration/AAConfig'


/* #endregion imports */

const MaintenancePage = () => {

  const urlParameters = useParams();

  const { currentToken, currentOperator } = useContext(UserContext);

  const [loading, setLoading] = useState(false);

  const [apiURLs, setApiURLs] = useState(null);

  const [data, setData] = useState();
  const [closedUSRN, setClosedUSRN] = useState(urlParameters ? urlParameters.maintenanceUSRN : null);

  /* HOOKS*/
  useEffect(() => {

    async function SetUpApi() {
      if (apiURLs == null) {
        const canEditUrl = await GetCanOperatorEditASDDataURL(currentToken);
        const usrnUrl = await GetStreetFromUSRN(currentToken);
        const searchUrl = await GetSearchStreetURL(currentToken);
        const closedstreetsURL = await GetClosedStreetsWithASDURL(currentToken);

        setApiURLs({ canEditASD: canEditUrl, usrnApiDetails: usrnUrl, SearchApiDetails: searchUrl, ClosedStreetsWithASD: closedstreetsURL });
      }
    }

    async function SetUpClosedStreetData() 
    {
      if (!data) 
      {
        if (apiURLs && currentOperator) 
        {
          setLoading(true);

          const resData = await fetch(`${apiURLs.ClosedStreetsWithASD.url}\\${currentOperator}`, {
                headers: apiURLs.ClosedStreetsWithASD.headers,
                crossDomain: true,
                method: apiURLs.ClosedStreetsWithASD.type,
                })
            .then(res => (res.ok ? res : Promise.reject(res)))
            .then(res => res.json())
            .then(
              (result) => {
                return result;
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                console.log("closed streets fetch error = ", error);
              }
            ).then(() => {
              setLoading(false);
            });

            if(resData)
              setData(resData);
        }

        console.log("[maintenance page] apiURLs && currentOperator", apiURLs, currentOperator);
     }
    }

    if (apiURLs === null)
      SetUpApi();

    SetUpClosedStreetData();

    return () => {     };

  }, [apiURLs]);

  const handleClick = (usrn) => {
    setClosedUSRN(usrn);
  }

  function ShowMaintenanceList() {
    return (
      <div className="maintenance-page"  >

        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item>
            <Grid container justifyContent="flex-start">
              <Grid item>
                <Typography variant="h6" display="inline">Closed Streets With ASD</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={8}>
                <MaintenanceStreetTableView streets={data} handleSelection={handleClick} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  function ShowASDList() {
    const redirection = `maintenance/${closedUSRN}`;
    return (
      <Redirect to={redirection} />
      );
  }

  if (!loading) {
    if (isNaN(closedUSRN)) {
      return ShowMaintenanceList();
    }
    else
    {
      return ShowASDList();
    }
  }
  else
    return ( "Loading Maintenance Page" );
};

export default MaintenancePage;

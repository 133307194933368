/* #region header */
//**************************************************************************************************
//
//  Description: Drop down list with a avatar and colour based
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   09.12.20 Sean Flook         WI38224 Added ability to disable the control.
//    003   18.01.21 Sean Flook         WI39041 Handle the onChange event so that the control can update the underlying data.
//    004   21.01.21 Sean Flook         WI38232 Changes required for saving the data.
//    005   08.02.21 Sean Flook         WI38232 Set required.
//    006   19.02.21 Sean Flook         WI39185 Adjusted the width.
//    007   03.03.21 Sean Flook         WI39198 Use Typography component to display the text.
//    008   09.03.21 Sean Flook         WI39240 Corrected width.
//    009   10.03.21 Sean Flook         WI39198 Ensure everything is displayed on a single line and we are not using inline styling.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
/* #endregion header */

/* #region imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { deepPurple, blue, grey, cyan, orange, brown,pink } from "@material-ui/core/colors";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Typography } from "@material-ui/core";

import Grid from "@material-ui/core/Grid";
/* #endregion imports */

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    }
  },
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: 20,
    height: 20,
  },
  blueWide: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    width: 40,
    height: 20,
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: 20,
    height: 20,
  },
  purpleWide: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    width: 40,
    height: 20,
  },
  orange: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: orange[900],
    width: 20,
    height: 20,
  },
  orangeWide: {
    color: theme.palette.getContrastText(orange[900]),
    backgroundColor: orange[900],
    width: 40,
    height: 20,
  },
  cyan: {
    color: theme.palette.getContrastText(cyan[500]),
    backgroundColor: cyan[500],
    width: 20,
    height: 20,
  },
  cyanWide: {
    color: theme.palette.getContrastText(cyan[500]),
    backgroundColor: cyan[500],
    width: 40,
    height: 20,
  },
  grey: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    width: 20,
    height: 20,
  },
  greyWide: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    width: 40,
    height: 20,
  },
  brown: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
    width: 20,
    height: 20,
  },
  brownWide: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
    width: 40,
    height: 20,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: 20,
    height: 20,
  },
  pinkWide: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
    width: 40,
    height: 20,
  },
}
));


function AvatarSelectListItem(props) {

  const { avatar_id, name, colour } = props;
  const classes = useStyles();

  const avatarColours = [{ "colour": "orange", "classesName": classes.orange },
    { "colour": "orangeWide", "classesName": classes.orangeWide },
    { "colour": "cyan", "classesName": classes.cyan },
    { "colour": "cyanWide", "classesName": classes.cyanWide },
    { "colour": "red", "classesName": classes.red },
    { "colour": "redWide", "classesName": classes.redWide },
    { "colour": "blue", "classesName": classes.blue },
    { "colour": "blueWide", "classesName": classes.blueWide },
    { "colour": "brown", "classesName": classes.brown },
    { "colour": "brownWide", "classesName": classes.brownWide },
    { "colour": "pink", "classesName": classes.pink },
    { "colour": "pinkWide", "classesName": classes.pinkWide },
    { "colour": "purple", "classesName": classes.purple },
    { "colour": "purpleWide", "classesName": classes.purpleWide },
    { "colour": "greyWide", "classesName": classes.greyWide }];

  const avatarColour = avatarColours.find((x) => x.colour === colour);

  return (
    <Grid container wrap="nowrap" spacing={1} alignItems="center" justifyContent="center">
      <Grid item>
        <Avatar
          variant="rounded"
          alt="{name}"
          className={avatarColour ? avatarColour.classesName : classes.grey}
        >
          <Typography variant="caption" display="block">
            {avatar_id}
          </Typography>
        </Avatar>
      </Grid>
      <Grid item xs={10} sm zeroMinWidth>
        <Typography variant="body2" display="block" noWrap>
          {name}
        </Typography>
      </Grid>
    </Grid>
  );
};


class AAAvatarSelect extends React.Component {

  state = {
    data: this.props.data,
    placeholder: this.props.placeholder,
    disabled: this.props.disabled, //SF WI38224
    avatarField: this.props.avatarField,
    textField: this.props.textField,
    keyField: this.props.keyField,
    selectedKey: this.props.selectedKey ?? 1,
    colour: this.props.colour,
    requiredField: this.props.requiredField,
    onChange: this.props.onChange
  };

  handleChange = (event) => {
    const { keyField } = this.state;
    this.setState({ selectedKey: event.target.value[keyField] });
  }

  valueRender = (element, value) => {
    if (!value) {
      return element;
    }

    const children = this.renderListItem(value);

    return React.cloneElement(element, { ...element.props }, children);
  };

  renderListItem = (data) => {
    const { avatarField, textField, keyField, colour } = this.state;
    return (
      <AvatarSelectListItem key={data[keyField]} avatar_id={data[avatarField]} name={data[textField]} colour={colour} />
    );
  };

  itemRender = (li, itemProps) => {
    const itemChildren = this.renderListItem(itemProps.dataItem);
    return React.cloneElement(li, li.props, itemChildren);
  };

  handleChange = (event) => {

    const { keyField, onChange } = this.state;

    this.setState({
      selectedKey: event.target.value[keyField]
    });
    console.log("DEBUG handleChange", event.target.value[keyField]);
    onChange(event);
  }


  render() {
    const { data, placeholder, disabled, keyField, textField, selectedKey, requiredField } = this.state; //SF WI38224

    const selectedValue = data.find(x => x[keyField] === selectedKey);


    return (
      <DropDownList
        className="form__component"
        data={data}
        textField={textField}
        dataItemKey={keyField}
        placeholder={placeholder}
        disabled={disabled} //SF WI38224
        itemRender={this.itemRender}
        valueRender={this.valueRender}
        value={selectedValue}
        required={requiredField}
        onChange={this.handleChange}
      />
    );
  }
}

export default AAAvatarSelect;

/**************************************************************************************************
//
//  Description:  main start page
//
//  Copyright:    2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   30.10.20 Joel Benford               Added redux
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router} from 'react-router-dom';

import App from './App';
import { store } from "./store/store";
/* #endregion imports */

const baseUrl = document.getElementsByTagName('base')[0];//.getAttribute('href');
//console.log("version", baseUrl);

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Router>
            <App />
    </Router>,
  </Provider>,
  rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();


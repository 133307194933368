/**************************************************************************************************
//
//  Description:  Page Routing class
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   19.01.21 Sean Flook                 Corrected spelling of Gazetteer.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    003   27.05.21 Peter Bryden       WI39308 add in maintenance page routing
//    004   09.06.21 Peter Bryden       WI39308 make sure maintenance route is in currentroute
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, {useContext} from "react";
import { Route, Switch} from "react-router-dom";
import { withRouter } from "react-router-dom";

import Homepage from "./pages/Homepage";
import Gazetteerpage from "./pages/Gazetteerpage";
import Taskmanagementpage from "./pages/Taskmanagementpage";
import UserAdminpage from "./pages/UserAdminpage";
import Maintenancepage from "./pages/MaintenancePage";
import MaintenanceASDSelectionPage from "./pages/MaintenanceASDSelectionPage";

import { UserContext } from "./contexts/userContext";
/* #endregion imports */


export const HomeRoute = "/";
export const GazetteerRoute = "/gazetteer";
export const TaskManagementRoute = "/taskmanagement";
export const UserAdminRoute = "/useradmin";
export const MaintenanceRoute = "/maintenance";
export const ASDMaintenanceRoute = "/maintenance/:maintenanceUSRN";

export const CurrentRoute = () => {
  const currentRoute = window.location.pathname;

  if (currentRoute.startsWith(GazetteerRoute))
    return GazetteerRoute;
  else if (currentRoute.startsWith(MaintenanceRoute))
    return MaintenanceRoute;
  else if (currentRoute.startsWith(UserAdminRoute))
    return UserAdminRoute;  

  return HomeRoute;
};


const PageRouting = () => {

  const { currentToken, currentUser } = useContext(UserContext);

  const isLoggedIn = !!(currentUser && currentToken);

  return (
    <Switch>
        <Route component={isLoggedIn ? Gazetteerpage : Homepage} exact path={`${GazetteerRoute}/:usrn`} />
        <Route component={isLoggedIn ? Taskmanagementpage : Homepage} path={TaskManagementRoute} />
        <Route component={isLoggedIn ? UserAdminpage : Homepage} path={UserAdminRoute} />

        <Route component={isLoggedIn ? Maintenancepage : Homepage} exact path={MaintenanceRoute} />
        <Route component={isLoggedIn ? MaintenanceASDSelectionPage : Homepage} exact path={ASDMaintenanceRoute} />
        <Route path="*" exact component={Homepage} />
    </Switch>
  )
};

export default withRouter(PageRouting);

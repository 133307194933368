/**************************************************************************************************
//
//  Description: user Context
//
//  Copyright:    © 2022 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.1.0.0 changes
//    001   13.09.22 Peter Bryden               Initial load
//#endregion Version 1.1.0.0 changes
//
/**************************************************************************************************/


import React, { useEffect, useState } from "react";
import { GetWhoAmIUrl } from "./../configuration/AAConfig_Security";

/* #endregion imports */

const UserContext = React.createContext();
UserContext.displayName = "userContext";

function UserContextProvider({props, children }) {

    //const { currentUser: currentUser, onUserChange: handleUserChange }
    const tokenStorageName = "curToken";

    const GetLocalStorage = (key) => {
        const val = localStorage.getItem(key);
        return (!val || (val === 'null')) ? null : val;
    }

    const [loginDetails, setLoginDetails] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [userError, setUserError] = useState(null);
    const [currentToken, setCurrentToken] = useState(GetLocalStorage(tokenStorageName));
    const [currentOperator, setCurrentOperator] = useState(null);
    const [showLogin, setShowLogin] = useState(!currentToken);



    useEffect(() => {

        const storedtokenData = localStorage.getItem(tokenStorageName);

        if (currentToken && (storedtokenData !== currentToken))  {
            localStorage.setItem(tokenStorageName, currentToken);
        }
        else if (!currentToken && (storedtokenData && storedtokenData.length > 0)) {
            setCurrentToken(storedtokenData);
        }
    }, []);

  
    // Local Storage: setting & getting data
    useEffect( () => {
        
        //console.log(`current token (useeffect) `, currentToken);
        
        const storedTokenData = GetLocalStorage(tokenStorageName);

            if (currentToken && (storedTokenData !== currentToken))  {
                localStorage.setItem(tokenStorageName, currentToken);
            }

            if (!currentToken && storedTokenData) {
                setCurrentToken(storedTokenData);
            }
            
            async function GetUser()
            {

               if(!currentToken)  return null;

            const userUrl = await GetWhoAmIUrl(currentToken);
            
            if (userUrl)
            {
                    // console.log("[SF] GetUserInformation", userUrl);
                    const userInfo = await fetch(`${userUrl.url}`, {
                        headers: userUrl.headers,
                        crossDomain: true,
                        method: "GET",
                    })
                        .then((res) => (res.ok ? res : Promise.reject(res)))
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                //console.log("[SF] GetUserInfo", result);
                                return result;
                            },
                            (error) => {
                                //console.log("ERROR Get user information", error);
                                return null;
                            }
                        );
                
                if (userInfo)
                {
                    if (userInfo.active && !userInfo.isDeleted)
                    {
                        setUserError(null);
                        return userInfo;
                    }
                    else
                    {
                        if (!userInfo.active)
                                setUserError("You are not an active user on this system.");
                        else setUserError("This user has been deleted.");
                    }
                }
                else
                {
                    setUserError("Unable to get user information.");
                }
            }
            else
            {
                setUserError("Unable to get user information URL.");
            }

                return null;
            };
           
            async function GetDetails()
            {
                //console.log("extGetDetails ", currentUser, currentToken);

                if (currentToken && !currentUser) {
                    const userinfo = await GetUser();
                    setCurrentUser(userinfo);
                }
            };

            GetDetails();

    }, [currentToken]);


    useEffect( () =>  {

        if (loginDetails && loginDetails.token) {
            setCurrentToken(loginDetails.token);
        }
        
    }, [loginDetails])


    useEffect(() => {

        function GetOperatorId(userInfo) {

            //console.log("extrainfo ", userInfo, currentToken);

            if(!userInfo) return null;

            const extraInfo = userInfo.extraInformation;
            const operatorKeyName = "TR Operator Id";
            
            if (extraInfo) 
            {
                const isFound = extraInfo.some(x => {
                    return (x.key === operatorKeyName);
                });

                
                if (isFound) {
                    setUserError(null);
                    return extraInfo.find(z => z.key === operatorKeyName).value;
                }
                else 
                {
                    setUserError(`Unable to get find ${operatorKeyName}.`);
                }
            }
            else 
            {
                setUserError("Unable to get extra information.");
            }

            return null;
        };

        if(currentUser)
        {
            const operatorId = GetOperatorId(currentUser);
            setCurrentOperator(operatorId);
        }

        setShowLogin(!currentToken) 
    }, [currentUser])


    //const tokenData = JSON.parse(localStorage.getItem('curToken'))

    function logoff() {

        localStorage.removeItem(tokenStorageName);
        setShowLogin(true);
        setCurrentToken(null);
        setCurrentUser(null); 
        setCurrentOperator(null);
        setLoginDetails(null);

        //window.location.reload();
    }

    function updateLoginDetails(newLogin) {
        //console.log(`updatelogindetails : ${newLogin}`);
        setLoginDetails(newLogin);
    }

    function updateCurrentUser(newUser) {
        //console.log(`updatecurrentuser : ${newUser}`);
        setCurrentUser(newUser);
    }

    function updateShowLogin(newshowLogin) {
        //console.log(`show login set : ${newshowLogin} :: ${showLogin}`);
        setShowLogin(newshowLogin);
    }

    return (
        <UserContext.Provider value={{ currentToken, currentUser, currentOperator, showLogin, logoff, updateLoginDetails, updateCurrentUser, updateShowLogin }}>
            {children}
        </UserContext.Provider>
    )
}

export { UserContextProvider, UserContext }

/**************************************************************************************************
//
//  Description: Maintenance ASD List View
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.1.0 changes
//    001   27.05.21 Peter Bryden        WI39324 show list of asds associated with closed usrn
//    002   04.06.21 Peter Bryden        WI39324 added in state variables to handle data being updated
//    003   08.06.21 Paula Dormer        WI39308 Add no default comment to switch
//    004   09.06.21 Paula Dormer        WI39308 Only highlight open ASD on closed streets.
//    005   18.06.21 Peter Bryden        WI39514 Added showlastedited property to asdlist
//    006   29.06.21 Paula Dormer        WI39308 Only return ASD belonging tothe Operator.
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AAAssociatedStreetDataList from "../AAAssociatedStreetDataList"
import {
  GetOSGRoadStatusCodes_URL,
  GetOSGReinstatementTypeCodes_URL,
  GetOSGSpecialDesignationCodes_URL,
  GetOSGSwaOrgRefs_URL,
  GetStreetFromUSRN
} from '../../configuration/AAConfig'

import { UserContext } from '../../contexts/userContext';

const useStyles = makeStyles((theme) => ({
  /* Styles applied to the root element. */
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1.5),
    },
    // Default root styles
    color: 'inherit',
    chipColour: 'lightgray',
    // We disable the focus ring for mouse, touch and keyboard users.
    outline: 0,

    '&:hover': {
      color: 'white',
      // Set hover color
      backgroundColor: 'lightblue',
      chipColour: 'blue'
    },
  },

  hover: {},
}));

export const MaintenanceASDListView = ({ usrn, handleChecked, reloadData, handleDataLoaded, operatorID }) => {  //PD WI39308

  //const classes = useStyles();  //pjb
  
  const [Lookup_OSGRoadStatusCodes, setLookup_OSGRoadStatusCodes] = useState();
  const [Lookup_OSGReinstatementTypeCodes, setLookup_OSGReinstatementTypeCodes] = useState();
  const [Lookup_OSGSpecialDesignationCodes, setLookup_OSGSpecialDesignationCodes] = useState();
  const [Lookup_OSGSwaOrgRefs, setLookup_OSGSwaOrgRefs] = useState();

  const [usrnData, setUSRNData] = useState();
  const [isloaded, setisLoaded] = useState(false);
  const [checkedData, setCheckedData] = useState([]);

  const { currentToken } = useContext(UserContext);

  async function fetchData(url) {

    return await fetch(url)
      .then(response => response.json())
      .then(result => {
        return result;
      })
      .catch(e => {
        console.log(e);
      });
  }

  function handleASDCheck(asdType, data) {

    const filteredData = checkedData ? checkedData.filter(x => x.asdType !== asdType) : [];
    const newData = data ? [...data] : [];
    const updatedData = [...filteredData, ...newData];

    setCheckedData(updatedData);
    handleChecked(updatedData);
  }


  //PD WI39308++
  //Filters the returned ASD so only the pnes belonging to the operator are displayed
  function filterASDData(streetData, currentOperatorId) {

    if (!streetData || isNaN(currentOperatorId))
      return;
    
    const operatorSpecialDesignations = (streetData.specialDesignations) ? streetData.specialDesignations.filter(x => x.custodianCode === currentOperatorId) : streetData.specialDesignations;

    const operatorReinstatementCatergories = (streetData.reinstatementCatergories) ? streetData.reinstatementCatergories.filter(x => x.custodianCode === currentOperatorId) : streetData.reinstatementCatergories;
    const operatorMaintenanceResponsibilities = (streetData.maintenanceResponsibilities) ? streetData.maintenanceResponsibilities.filter(x => x.custodianCode === currentOperatorId) : streetData.maintenanceResponsibilities;
         
    let newUSRNData = streetData;
    newUSRNData.specialDesignations = operatorSpecialDesignations;
    newUSRNData.reinstatementCatergories = operatorReinstatementCatergories;
    newUSRNData.maintenanceResponsibilities = operatorMaintenanceResponsibilities;

    setUSRNData(newUSRNData);
  }
  //PD WI39308--

  const renderForm = () => {
     
    return usrnData ? <AAAssociatedStreetDataList
      asdData={usrnData}
      Lookup_OSGRoadStatusCodes={Lookup_OSGRoadStatusCodes}
      Lookup_OSGReinstatementTypeCodes={Lookup_OSGReinstatementTypeCodes}
      Lookup_OSGSpecialDesignationCodes={Lookup_OSGSpecialDesignationCodes}
      Lookup_OSGSwaOrgRefs={Lookup_OSGSwaOrgRefs}
      editable={false}
      handleChecked={(asdType, data) => handleASDCheck(asdType, data)}
      highlightItem={true}
      showLastEdited={false}
    /> : "loading";
  }

  useEffect(() => {

   // console.log("useeffect ", operatorID, usrn, reloadData);

    async function fetchUsrnData(usrnVal) {
      const dataURL = await GetStreetFromUSRN();
      if (dataURL) {
        return await fetchData(`${dataURL.url}/${usrnVal}`);
      }
    }

    //PD WI39308++
    if (!isNaN(operatorID)) {   

      console.log("Operator id is a number");

      setUSRNData(null);

      handleDataLoaded(false);

      fetchUsrnData(usrn).then((data) => {
        handleDataLoaded(true); 
        filterASDData(data, operatorID);
      });
      setCheckedData([]);
    }

  }, [usrn, reloadData, operatorID])
  //PD WI39308--

  useEffect(() => {

    let isCancelled = false;

    const LoadLookups = async () => {
      const Lookups = [
        { "url": await GetOSGRoadStatusCodes_URL(currentToken), "data": Lookup_OSGRoadStatusCodes, "id": "Lookup_OSGRoadStatusCodes" },
        { "url": await GetOSGReinstatementTypeCodes_URL(currentToken), "data": Lookup_OSGReinstatementTypeCodes, "id": "Lookup_OSGReinstatementTypeCodes" },
        { "url": await GetOSGSpecialDesignationCodes_URL(currentToken), "data": Lookup_OSGSpecialDesignationCodes, "id": "Lookup_OSGSpecialDesignationCodes" },
        { "url": await GetOSGSwaOrgRefs_URL(currentToken), "data": Lookup_OSGSwaOrgRefs, "id": "Lookup_OSGSwaOrgRefs" }
      ];

      Lookups.forEach(async (lookup) => {

        if (!isCancelled && !lookup.data) {
          const lookupURL = lookup.url;

          const result = await fetchData(lookupURL.url)

          if (!isCancelled) {

            switch (lookup.id) {
              case "Lookup_OSGRoadStatusCodes": setLookup_OSGRoadStatusCodes(result); break;
              case "Lookup_OSGReinstatementTypeCodes": setLookup_OSGReinstatementTypeCodes(result); break;
              case "Lookup_OSGSpecialDesignationCodes": setLookup_OSGSpecialDesignationCodes(result); break;
              case "Lookup_OSGSwaOrgRefs": setLookup_OSGSwaOrgRefs(result); break;
              //no default
            }
          }
        }
      });
    }

    if (!isloaded) {
      LoadLookups();
    }

    return () => {
      isCancelled = true;
    };

  }, [isloaded])

  useEffect(() => {
    setisLoaded(false);
  }, [usrnData])

  const bIsloaded = !(!Lookup_OSGRoadStatusCodes || !Lookup_OSGReinstatementTypeCodes || !Lookup_OSGSpecialDesignationCodes || !Lookup_OSGSwaOrgRefs);

  if (bIsloaded && !isloaded) {
    setisLoaded(true);
  }

  return isloaded && usrnData ? (
    renderForm()
  ) : "Loading Associated Street Data";
}


/**************************************************************************************************
//
//  Description: Header bar with street search 
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    001   01.07.21 Paula Dormer        WI39308 No longer have USRN and streetname in the header.
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, {useContext} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { isWidthUp } from "@material-ui/core/withWidth";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import AAStreetSearch from "./AAStreetSearch";

/* #endregion imports */

const styles = props => ({
  foo: {
    height: `${props.y || 50}px`,
    background: props.theme.palette.primary.light,
    color: props.theme.palette.getContrastText(props.theme.palette.primary.light)
  },
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18,
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: "relative",
    marginRight: 8,
    borderRadius: props.theme.shape.borderRadius,
    background: props.theme.palette.grey[200],
    "&:hover": {
      background: props.theme.palette.grey[300],
    },
    marginLeft: 0,
    width: "100%",
    [props.theme.breakpoints.up("sm")]: {
      marginLeft: props.theme.spacing(1),
      width: "400px",
    },
  },
  searchIcon: {
    width: props.theme.spacing(9),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    borderRadius: 4,
    paddingTop: props.theme.spacing(1),
    paddingRight: props.theme.spacing(1),
    paddingBottom: props.theme.spacing(1),
    paddingLeft: props.theme.spacing(10),
    transition: props.theme.transitions.create("width"),
    width: "100%",
    [props.theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200,
      },
    },
  },
});


const withStylesProps = styles =>
  Component =>
    props => {
      const [streetName] = React.useState(props.streetName);
      const Comp = withStyles(theme => styles({ ...props, theme }))(Component);
      return <Comp {...props} />;
    };


export default withStylesProps(styles)(

  props =>
    (<>
      <Typography noWrap color={"textSecondary"} className={props.classes.header}> 
        {'Scottish Trunk Roads'}
      </Typography>
      <div className={props.classes.grow} />
      <div className={props.classes.search}>
        {/*}
      <div className={classes.searchIcon}>
               {    <SearchIcon />
            </div>
            */}
        <div>
          <AAStreetSearch handleChange={props.setStreet} usrn={props.usrn} streetName={props.streetName} placeholder="Search..." classes={{
            root: props.classes.inputRoot,
            input: props.classes.inputInput,
          }}
          />
        </div>

        {/*  
      <InputBase
        placeholder="Search…"
        autocomplete="section-blue streetsearch"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
            />
            */}
      </div>
      {props.screen === "xs" && (
        <IconButton>
          <Icon>more_vert</Icon>
        </IconButton>
      )}
      {props.screen === "sm" && (
        <>
          <IconButton>
            <Icon>favorite</Icon>
          </IconButton>
          <IconButton>
            <Icon>more_vert</Icon>
          </IconButton>
        </>
      )}
      {isWidthUp("md", props.screen) && (
        <>
          <IconButton>
            <Icon>favorite</Icon>
          </IconButton>
          <IconButton>
            <Icon>phone</Icon>
          </IconButton>
          <IconButton>
            <Icon>camera</Icon>
          </IconButton>
        </>
      )}
    </>
    )
);

withStylesProps.propTypes = {
  screen: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
};

withStylesProps.defaultProps = {
  screen: null,
};



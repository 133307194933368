//#region header */
/**************************************************************************************************
//
//  Description: User Admin Page
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   15.03.21 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//#region Version 1.1.0.0 changes
//    002  13.09.22 Peter Bryden               updated for user access only
//#endregion Version 1.1.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useEffect, useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';

import { UserContext } from "../contexts/userContext";
import { UpdateMyPassword } from "../configuration/AAConfig_Security";
import { getOperatorsAsync } from "../services/api";

import EditUserDialog from '../dialogs/EditUserDialog'
import ResetUserPasswordDialog from '../dialogs/ResetUserPasswordDialog'
import ErrorDialog from "../dialogs/ErrorDialog";

/* #endregion imports */

const styles = ({ breakpoints }) => ({
  root: {
    padding: 10,
    [breakpoints.up("sm")]: {
      padding: 10,
      maxWidth: '50%',
      margin: "auto",
    },
    [breakpoints.up("md")]: {
      maxWidth: '90%',
    },
  },
});

const ButtonCommandCell = props => {
  return (
    <td>
      <Button variant="contained" color="primary" onClick={() => props.onClick(props.dataItem)}>
        {props.btnText}
      </Button>
    </td>
  );
};

const UserAdminpage = ({ classes }) => {

  const { currentToken, currentUser, currentOperator } = useContext(UserContext);
  const [users, setUsers] = useState(null);
  const [operators, setOperators] = useState(null);
  const [editItem, setEditItem] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const [showEditDialog, setShowEditDialog] = useState();
  const [showPasswordDialog, setShowPasswordDialog] = useState();
  const [showErrorDialog, setShowErrorDialog] = useState();
  const [passwordErrors, setPasswordErrors] = useState();

  const GetOperatorName = (userOperatorId) => { 
    if(operators)
    {
       const selected_operator = operators.find(op => op.operatorId == userOperatorId);
       return selected_operator ? selected_operator.operatorName : `cant find ${userOperatorId}`;
    }

    return "Unknown";
  };

  
  useEffect(() => {

    const loadOperators = async () => {
      const operatorResult = await getOperatorsAsync(currentToken);
      if(operatorResult)
        setOperators(operatorResult);
    };

    if(!operators || operators.length === 0)
      loadOperators();

  }, []);


  useEffect(() => {
    setShowErrorDialog(errorMsg && errorMsg.length > 0)
  }, [errorMsg]);  
  
  useEffect(() => {

    const loadUsers = async () => {
      if (currentOperator) {
        if(currentUser && operators) 
        {
          setUsers([{
            id : currentUser.id,
            email : currentUser.email,
            name : currentUser.auditName,
            operatorid : currentOperator,
            operator : GetOperatorName(currentOperator),
            isAdmin : false
          }]);
        }
      }
    }   

    //console.log("operators result ",operators, currentOperator, currentUser);

    if(!users || users.length === 0) {
      loadUsers();
    }

  }, [operators, currentOperator, currentUser]);
  


  const openEditDialog = item => {
    setEditItem(item);
    setShowEditDialog(true);
  }

  const openPasswordDialog = item => {
    setEditItem(item);
    setShowPasswordDialog(true);
  }


  const EditCommand = props => (
    <ButtonCommandCell {...props} btnText={"Edit"} onClick={openEditDialog} />
  );

  const PasswordReset = props => (
    <ButtonCommandCell {...props} btnText={"Password"} onClick={openPasswordDialog} />
  );

  const handleCancelEditDialog = () => { setShowEditDialog(false); };

  const updateUser = async (editedUser) => {
   
  };

  const updatePassword = async (id,password) => {
    
    if (currentToken) {

        const passwordChangeURL = await UpdateMyPassword(currentToken);
  
        if(passwordChangeURL)
        {
          const errors = await fetch(`${passwordChangeURL.url}`, {
                headers: passwordChangeURL.headers,
                crossDomain: true,
                method: passwordChangeURL.type,
                body: JSON.stringify({ password: password })
                })
                .then(response => response.text())
                .then(text => {
                        return [];
                })
                .catch((error) => {
                  // console.log("[SF] handleLoginClick - exception", e);
                  // userContext.onUserChange(null);
                  switch (error.status) {
                    case 401:
                       return ["authorization failed - please logout and log back in again"];
                    default:
                      return [`An unknown error code(${error.status}) occurred, please report to support.`];
                  }
                });
               
             return errors;   
          }
          else
          {
            return ["find URL to change password."];            
          }
  
  

      /*
      const response = await fetch(`user/passwordreset/${id}/${password}`, {
        method: 'POST',
        cache: 'no-cache',
        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' },
      }).then(response => {
        if (response.status === 200) {
          return [];
        } else if (response.status == 404) {
          return response.json().then(errs => {
            return errs;
					})
        }
        else if (response.status === 401) {
          return ["authorization failed - please logout and log back in again"];
        }
      });

      return response;
      */
    }
    else {
      return ["authorization failed - please logout and log back in again"];
    }
  };

  const handleSubmitEditDialog = (updated_user) => {
    updateUser(updated_user);
    console.log("submit edit form",updated_user);
    setShowEditDialog(false);
  }

  const handleClosePasswordDialog = async (id,password) => { setShowPasswordDialog(false); }

  return (
    <div className={classes.root}>
      <Typography variant={"overline"}>User Admin</Typography>

      <Grid
        style={{ height: '60vh', width:'85%' }}
        data={!users ? [] : [...users]}
      >
        <Column field="id" width={0} show={false} sortable={false} resizable={false} locked={true} minResizableWidth={0} editable={false} />
        <Column field="email" title="Email" width="350px" />
        <Column field="name" title="Name" width="250px" />
        <Column field="operatorid" width={0} show={false} sortable={false} resizable={false} locked={true} minResizableWidth={0} editable={false} />
        <Column field="operator" title="Operator" width="350px" />
        {/*
        <Column
          field="isAdmin" title="Admin" width="100px"
          cell={props => (
            <td>
              <input disabled type="checkbox" checked={props.dataItem[props.field]} />
            </td>
          )}
          />
        */}
        
        <Column cell={PasswordReset} width={145} editable={false} />
        {/*<Column cell={EditCommand} width="120px" />*/}
      </Grid>
      {showEditDialog && <EditUserDialog isOpen={showEditDialog} user={editItem} operators={operators} cancelHandler={handleCancelEditDialog} submitHandler={handleSubmitEditDialog} />}
      {showPasswordDialog && <ResetUserPasswordDialog isOpen={showPasswordDialog} user={editItem} handleCloseDialog={handleClosePasswordDialog} updatePasswordHandler={updatePassword} />}
      {showErrorDialog && <ErrorDialog isOpen={showErrorDialog} message={errorMsg} handleClick={() => {setErrorMsg(null)}} />}
    </div >
  );
}

export default withStyles(styles)(UserAdminpage);

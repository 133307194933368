/**************************************************************************************************
//
//  Description: Display list of ASD items
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   15.01.21 Sean Flook         WI39041 Added an Add button and associated events.
//    003   26.01.21 Sean Flook         WI38232 Only display the add button if the user is allowed to create new records on this street.
//    004   29.01.21 Peter Bryden       WI38232 cleaned up code and handled save back to forms
//    005   02.02.21 Sean Flook         WI38232 Only allow editing of geometry if part of road selected.
//    006   19.02.21 Sean Flook         WI39185 Adjusted the width.
//    007   09.03.21 Sean Flook         WI39166 Added tooltip to icons.
//    008   09.03.21 Sean Flook         WI39240 Corrected width.
//    009   11.03.21 Sean Flook         WI39198 Changed the special designation icon.
//    010   21.03.21 Peter Bryden       WI39243 Add context to hold state of treelist open states
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    011   27.05.21 Peter Bryden       WI39324 Add in checkbox handler for use with maintenance page
//    012   08.06.21 Peter Bryden       WI39324 show closed ASDs in different colour
//    013   08.06.21 Paula Dormer       WI39308 switch colours around, open ASDs in red, use state to
//                                              work out if ASD is closed
//    014   09.06.21 Paula Dormer       WI39308 Only highlight open ASD on closed streets.
//    015   18.06.21 Peter Bryden       WI39514 Added in functionality to scroll to and highlight asd item identified by lastedit property
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { brown, yellow, pink, green } from "@material-ui/core/colors";
import { useAAAssociatedStreetDataListState, useAAAssociatedStreetDataUpdateState } from '../contexts/AAAssociatedStreetDataListContext';

/* #endregion imports */

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested_1: {
    paddingLeft: theme.spacing(10),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  brown: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
  },
  yellow: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
  },
  normalASD: {
    width: '20vh'
  },
  highlightedASD: {
    color: 'red',
    width: '20vh',
  },
  selectedBackground: {
    color:'red'
	}

}));

const AAAssociatedStreetDataListItem = ({ title, type, itemIcon, colour, data, editable, itemSelect, itemAdd, handleChecked, forceChecked, highlightItem, lastEdited }) => {
  const classes = useStyles();
  const isEditable = editable;
  const autoShowRef = useRef();

  const getOpen = useAAAssociatedStreetDataListState();
  const toggleOpen = useAAAssociatedStreetDataUpdateState();

  const [open, setOpen] = useState(getOpen ? getOpen(title) : true)
  const [checkedAll, setCheckedAll] = useState();
  const [asdData, setAsdData] = useState(data);


  //FInd unique special designation types
  const handleOpenClose = () => {
    setOpen(!open);
    if (toggleOpen)
      toggleOpen(title);
  };

  const showListIcon = (icon) => {
    switch (icon) {
      case "PeopleRoundedIcon": return <PeopleRoundedIcon className={colour && avatarColours.find((x) => x.colour === colour).classesName} />;
      case "ReportProblemOutlinedIcon": return <ReportProblemOutlinedIcon className={colour && avatarColours.find((x) => x.colour === colour).classesName} />;
      case "BlurOnIcon": return <BlurOnIcon className={colour && avatarColours.find((x) => x.colour === colour).classesName} />;
      //no default
    }
  }

  function ShowCheckBoxAll() {
    return handleChecked ? <ListItemSecondaryAction>
      <Checkbox
        edge="end"
        onChange={(e) => handleAllChecked(e.target.checked)}
        checked={checkedAll}
        inputProps={{ 'aria-labelledby': title }}
      />
    </ListItemSecondaryAction>
      : "";
  }

  function UpdateCheck(id) {

    const itemPkId = id;
    let newCheckAll = true;
    const updatedData = asdData ? asdData.map((d) => {
      const newChecked = (d.pkId === itemPkId) ? !d.checked : d.checked;
      newCheckAll = newCheckAll && newChecked;

      return { ...d, checked: newChecked }
    }) : [];

    setCheckedAll(newCheckAll);
    setAsdData(updatedData);
  }


  useEffect(() => {
    if (asdData && handleChecked) {
      const checkedData = asdData ? asdData.filter(x => x.checked).map((i) => { return i.pkId; }) : [];
      handleChecked(checkedData);
    }

  }, [asdData])

  useEffect(() => {
    if (forceChecked !== null) {
      handleAllChecked(forceChecked)
    }
  }, [forceChecked])

  useEffect(() => {
    if (autoShowRef && autoShowRef.current)
      autoShowRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  })


  function ShowCheckBox(idx) {

    if (asdData && idx) {
      const item = asdData[idx.item];
      const id = item ? item.pkId : -1;
      const isChecked = item ? item.checked : false;

      return (handleChecked) ? (
        <ListItemSecondaryAction>
          <Checkbox
            edge="end"
            onChange={() => UpdateCheck(id)}
            checked={isChecked}
            inputProps={{ 'aria-labelledby': title }}
          />
        </ListItemSecondaryAction>
      ) : "";
    }
    else {
      return "";
    }
  }

  function IsASDClosed(item) {
    return (item.state === 2) ? true : false;
  }

  const handleAllChecked = (allCheck) => {
    if (handleChecked && (allCheck != null)) {
      const updatedData = data ? data.map((d) => {
        return { ...d, checked: allCheck }
      }) : [];
      setCheckedAll(allCheck);
      setAsdData(updatedData);
    }
  }


  const avatarColours = [{ "colour": "brown", "classesName": classes.brown },
  { "colour": "yellow", "classesName": classes.yellow },
  { "colour": "pink", "classesName": classes.pink }];

  avatarColours.find((x) => x.colour === colour);

  return (
    <React.Fragment>
      <ListItem button className="asd-ListItem">
        {open ? <ExpandLess onClick={handleOpenClose} /> : <ExpandMore onClick={handleOpenClose} />}

        {itemIcon && itemIcon.length > 0 ?
          <ListItemIcon onClick={handleOpenClose} >
            {showListIcon(itemIcon)}
          </ListItemIcon>
          : ''}

        <ListItemText
          primary={<Typography className="form__component" variant="subtitle1"
            onClick={handleOpenClose}>
            <strong>{title}</strong>
          </Typography>}
          secondary={type ?
            <React.Fragment>
              <Typography variant="subtitle2" onClick={handleOpenClose}>{type}</Typography>
              {isEditable ?
                <IconButton onClick={() => itemAdd(asdData ? asdData.length : 0)} size="small">
                  <Tooltip title="Add" arrow placement="right"><AddCircleOutlineIcon style={{ color: green[500] }} /></Tooltip>
                </IconButton>
                : null}
            </React.Fragment> : ''}
          className={itemIcon ? '' : classes.nested} />
        <ListItemAvatar onClick={handleOpenClose}>
          <Avatar className={classes.small}>
            <Typography variant="caption">
              {`${asdData ? asdData.length : 0} `}
            </Typography>
          </Avatar>
        </ListItemAvatar>
        <ShowCheckBoxAll />
      </ListItem>

      <Collapse in={open} timeout="auto">
        {data && data.map((d, idx) => (
          <List component="div" disablePadding key={`key_${idx}`} className="asd-ListItem" >
            <ListItem button className={`asd-ListItem ${itemIcon ? classes.nested : classes.nested_1}`}
              onClick={() => itemSelect(d.pkId, idx, data.length, d.wholeRoad)}
              selected={lastEdited && (lastEdited.pkId === d.pkId)}
            >
              <ListItemAvatar>
                <Avatar variant="square" className={`${classes.small}  ${colour && avatarColours.find((x) => x.colour === colour).classesName}`}>
                  <Typography variant="caption" >
                    {`${d.id}`}
                  </Typography>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={<Typography className={(!IsASDClosed(d) && highlightItem) ? classes.highlightedASD : classes.normalASD} variant="body2">{`${d.displayText}`} </Typography>} />
              <ShowCheckBox item={idx} />
            </ListItem>
            {lastEdited && (lastEdited.pkId === d.pkId) ? <span ref={autoShowRef}></span> : null}
          </List>
        ))}
      </Collapse>
    </React.Fragment>
  );
};

export default AAAssociatedStreetDataListItem;

//#region header */
/**************************************************************************************************
//
//  Description: Navigation sidebar
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   06.01.21 Sean Flook         WI39037 Changed to use a grid and IconButtons to prevent the horizontal scrollbar from appearing.
//    003   19.01.21 Sean Flook                 Corrected spelling of Gazetteer.
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    004   27.05.21 Peter Bryden        WI39308 updated search so it can be re-used & re-sized in maintenance page
//    005   09.06.21 Peter Bryden        WI39308 Added maintenance navigation button
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';

import ExploreIcon from "@material-ui/icons/Explore";
import HomeIcon from "@material-ui/icons/Home";
import BuildIcon from '@material-ui/icons/Build';

import AAUserAvatar from "./AAUserAvatar"

import { Tooltip } from "@material-ui/core";

import { HomeRoute, GazetteerRoute, CurrentRoute, MaintenanceRoute } from "../PageRouting";
/* #endregion imports */

const AANavContent = (props) => {

  const [selectedItem, setSelectedItem] = useState(CurrentRoute);
  const { usrn, history } = props;

  const handleItemClick = (routeName) => {
    setSelectedItem(routeName);
    history.push(routeName);
  };

  useEffect(() => {
    setSelectedItem(CurrentRoute);
  });

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid item xs={12}>
        <IconButton aria-label="home" disabled={selectedItem === HomeRoute} onClick={() => { handleItemClick(HomeRoute) }}>
          <Tooltip title="Home" arrow placement="right">
            <HomeIcon
              color={selectedItem === HomeRoute ? "inherit" : "primary"}
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
      
      <Grid item xs={12}>
        <IconButton aria-label="Gazetteer" disabled={selectedItem === GazetteerRoute || usrn == 0} onClick={() => { handleItemClick(`${GazetteerRoute}/${usrn}`) }}>
          <Tooltip title="Gazetteer" arrow placement="right">
            <ExploreIcon
              color={(selectedItem === GazetteerRoute || usrn == 0 ) ? "inherit" : "primary"}
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>

      <Grid item xs={12}>
        <IconButton aria-label="Maintenance" disabled={selectedItem === MaintenanceRoute } onClick={() => { handleItemClick(MaintenanceRoute) }}>
          <Tooltip title="Maintenance" arrow placement="right">
            <BuildIcon
              color={(selectedItem === MaintenanceRoute) ? "inherit" : "primary" }
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>


      <AAUserAvatar handleClick={(routeName) => { handleItemClick(routeName) }} />
    </Grid>
  );
};

AANavContent.propTypes = {};
AANavContent.defaultProps = {};

export default AANavContent;

/**************************************************************************************************
//
//  Description: form for data related to ASD
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   13.01.21 Peter Bryden               Added Click Handler to pass back click on ASD
//    003   15.01.21 Sean Flook         WI39041 Changes required for creating new records.
//    004   21.01.21 Sean Flook         WI38232 Changes required for saving the data.
//    005   25.01.21 Sean Flook         WI38232 Initial check-in for changes required for saving ASD records.
//    006   25.01.21 Sean Flook         WI38232 Removed debug messages.
//    007   26.01.21 Sean Flook         WI38232 Changes required to limit which streets a user can edit.
//    008   29.01.21 Peter Bryden       WI38232 cleaned up code and handled save back to forms
//    009   01.02.21 Sean Flook         WI38232 If the save fails remain on the current form.
//    010   02.02.21 Sean Flook         WI38232 Set seqNum to 0 for new records.
//    011   02.02.21 Sean Flook         WI38232 Only allow editing of geometry if part of road selected.
//    012   04.02.21 Sean Flook         WI38232 Changed order of calls in changeForm to prevent the map being redrawn when not required.
//    013   05.02.21 Sean Flook         WI38232 Pass the pkId through to handleAsdChange rather than the dataIdx which does not work for Special Designation records.
//    014   08.02.21 Sean Flook         WI38232 Pass through any validation errors when saving.
//    015   10.02.21 Sean Flook         WI38232 Set the custodian and authority code from the operator id.
//    016   19.02.21 Sean Flook         WI39186 When adding a new Special Designation set the title to Special Designation (x of y).
//    017   26.02.21 Peter Bryden       WI39153 Add confirmation dialog if trying to leave during edit
//    018   11.03.21 Peter Bryden               Reset edit check flags once a item has been saved or deleted
//    019   11.03.21 Peter Bryden               Added in usrnNavigation into ignored fields when checking for edits
//    020   08.04.21 Peter Bryden       WI39324 Changed title in forms and added in dataIdx and dataLen props to forms
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    021   27.05.21 Paula Dormer       WI39308 Default the Maintenance Responisibilty to Trunk Toads
//    022   08.06.21 Paula Dormer       WI39308 Add no default comment to switch
//    023   09.06.21 Paula Dormer       WI39308 Only highlight open ASD on closed streets.
//    024   18.06.21 Peter Bryden       WI39514 Added last used context
//    025   29.06.21 Paula Dormer       WI39308 Only highlight open ASD on closed streets in the 
//                                              mainetance screen.
#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import AAAssociatedStreetDataList from "../components/AAAssociatedStreetDataList"
import MaintenanceResponsibilityForm from "./AssociatedStreetData/MaintenanceResponsibilityForm"
import ReinstatementCategoryForm from "./AssociatedStreetData/ReinstatementCategoryForm"
import SpecialDesignationForm from "./AssociatedStreetData/SpecialDesignationForm"

import { useAAAssociatedStreetDataLastUsedState, useAAAssociatedStreetDataLastUsedUpdateState } from '../contexts/AAAssociatedStreetDataListContext';

import { ObjectComparison } from "./../utils/ObjectComparison"
import { getToday } from "./../utils/datetimeUtils"; 
import {
  GetAddMaintenanceResponsibilitiesURL,
  GetAddReinstatementCategoriesURL,
  GetAddSpecialDesignationsURL,
  GetUpdateMaintenanceResponsibilitiesURL,
  GetUpdateReinstatementCategoriesURL,
  GetUpdateSpecialDesignationsURL,
  GetDeleteMaintenanceResponsibilitiesURL,
  GetDeleteReinstatementCategoriesURL,
  GetDeleteSpecialDesignationsURL,

  GetOSGRoadStatusCodes_URL,
  GetOSGReinstatementTypeCodes_URL,
  GetOSGSpecialDesignationCodes_URL,
  GetOSGSwaOrgRefs_URL,
} from '../configuration/AAConfig'
import { current } from "@reduxjs/toolkit";

import { UserContext } from "../contexts/userContext";

/* #endregion imports */

const AssociatedStreetDataForm = (props) => {

  const { currentToken } = useContext(UserContext);

  const isEditable = props.editable;
  const handleAsdChange = props.changeHandler;
  const handleAsdSave = props.saveHandler;
  const handleAsdDelete = props.deleteHandler;
  const handleEditCheck = props.editCheckHandler
  const street_usrn = props.usrn;
  const asdData = props.asdData;
  const operatorId = props.operatorId;
  const confirmEditLoss = props.confirmEditLoss;

  const getLastUsedValue = useAAAssociatedStreetDataLastUsedState();
  const setLastUsedValue = useAAAssociatedStreetDataLastUsedUpdateState();

  const [addMaintenenaceResponsibilityURL, setAddMaintenenaceResponsibilityURL] = useState();
  const [addReinstatementCategoryURL, setAddReinstatementCategoryURL] = useState();
  const [addSpecialDesignationURL, setAddSpecialDesignationURL] = useState();
  const [updateMaintenenaceResponsibilityURL, setUpdateMaintenenaceResponsibilityURL] = useState();
  const [updateReinstatementCategoryURL, setUpdateReinstatementCategoryURL] = useState();
  const [updateSpecialDesignationURL, setUpdateSpecialDesignationURL] = useState();
  const [deleteMaintenenaceResponsibilityURL, setDeleteMaintenenaceResponsibilityURL] = useState();
  const [deleteReinstatementCategoryURL, setDeleteReinstatementCategoryURL] = useState();
  const [deleteSpecialDesignationURL, setDeleteSpecialDesignationURL] = useState();

  const [Lookup_OSGRoadStatusCodes, setLookup_OSGRoadStatusCodes] = useState();
  const [Lookup_OSGReinstatementTypeCodes, setLookup_OSGReinstatementTypeCodes] = useState();
  const [Lookup_OSGSpecialDesignationCodes, setLookup_OSGSpecialDesignationCodes] = useState();
  const [Lookup_OSGSwaOrgRefs, setLookup_OSGSwaOrgRefs] = useState();

  const [formData, setFormData] = useState();
  const [isloaded, setisLoaded] = useState(false);

  const today = getToday();

  const fetchData = async (urlDetails) => {

    return await fetch(`${urlDetails.url}`, {
          headers: urlDetails.headers,
          crossDomain: true,
          method: urlDetails.type,
          })
      .then(response => response.json())
      .then(result => {
        return result;
      })
      .catch(e => {
        console.log(e);
      });
  };

  const changeForm = (formName, pkId, dataIdx, dataLength, wholeRoad) => {

    setFormData({ "name": formName, "pkId": pkId, "idx": dataIdx + 1, "len": dataLength });

    if (handleAsdChange)
      handleAsdChange(formName, pkId, wholeRoad);
  }

  async function SaveForm(formName, saveData) {
    let saveURL = null;
    let validationErrors = [];

    if (saveData) {

      const bAddNewItem = (saveData.pkId && saveData.pkId < 0);

      //Creating a new record
      switch (formName) {

        case "MaintenanceResponsibility":
          saveURL = bAddNewItem ? addMaintenenaceResponsibilityURL : updateMaintenenaceResponsibilityURL;
          break;

        case "ReinstatementCategory":
          saveURL = bAddNewItem ? addReinstatementCategoryURL : updateReinstatementCategoryURL;
          break;

        case "SpecialDesignation":
          saveURL = bAddNewItem ? addSpecialDesignationURL : updateSpecialDesignationURL;
              break;

          //no default
      }

      if (saveURL) {
        validationErrors = await handleAsdSave(saveURL.url, saveURL.type, saveData, formName)
        if (validationErrors.length === 0) {
          handleEditCheck(false, null);
          changeForm('', null, null, null, true);
        }
      }
    }

    return validationErrors;
  }

  async function DeleteForm(formName, pkId) {
    let deleteURL = "";

    switch (formName) {
      case "MaintenanceResponsibility":
        deleteURL = (deleteMaintenenaceResponsibilityURL ? deleteMaintenenaceResponsibilityURL.url : "");
        break;

      case "ReinstatementCategory":
        deleteURL = (deleteReinstatementCategoryURL ? deleteReinstatementCategoryURL.url : "");
        break;

      case "SpecialDesignation":
        deleteURL = (deleteSpecialDesignationURL ? deleteSpecialDesignationURL.url : "");
            break;

        //no default
    }

    if (deleteURL.length > 0) {
      handleAsdDelete(deleteURL, formName, pkId);
      handleEditCheck(false, null);
      changeForm('', null, null, null, true);
    }
  }

  function CheckForEdit(srcData, currData) {
    const willLoseData = (currData.pkId < 0) ? true : !ObjectComparison(srcData, currData, ["wktGeometry", "lastUpdated", "lastUpdateDate", "changeType", "usrnNavigation"]);
    handleEditCheck(willLoseData, srcData.wktGeometry);
	}

  function FormReturn() {
    confirmEditLoss(() => changeForm('', null, null, null, true));
	}

  const renderForm = (formData) => {
    let formComp = null;
    let formName = formData ? formData.name : '';

    switch (formName) {
      case "MaintenanceResponsibility":
        let mrData = null;

        if (formData.pkId && formData.pkId > 0) {
          mrData = asdData.maintenanceResponsibilities.find(x => x.pkId === formData.pkId);
          setLastUsedValue(mrData.pkId, "mr");
        } else if (formData.pkId && formData.pkId < 0) {
          mrData = {
            "pkId": formData.pkId,
            "changeType": "I",
            "proOrder": 0,
            "usrn": street_usrn,
            "custodianCode": operatorId,
            "seqNum": 0,
            "maintainingAuthorityCode": operatorId,
            "wholeRoad": true,
            "specificLocation": null,
            "streetStatus": 4,  //PD WI39308
            "state": 1,
            "entryDate": today,
            "startDate": today,
            "lastUpdateDate": today,
            "endDate": null,
            "wktGeometry": null,
            "lastUpdated": null,
            "isLocal": true
          }
          setLastUsedValue(null, null);
        }

        formComp = <MaintenanceResponsibilityForm Lookup_OSGRoadStatusCodes={Lookup_OSGRoadStatusCodes}
          Lookup_OSGSwaOrgRefs={Lookup_OSGSwaOrgRefs}
          data={mrData}
          editable={isEditable}
          dataIdx={formData.idx}
          dataLen={formData.len}
          onReturnClick={(srcData, currData) => FormReturn()}
          onSaveClick={(asdData) => SaveForm(formName, asdData)}
          onDeleteClick={(pkId) => DeleteForm(formName, pkId)}
          onWholeRoadChange={(isWholeRoad) => changeForm(formName, formData.pkId, formData.idx - 1, formData.len, isWholeRoad)}
          logEdit={(sourceData, currentData) => CheckForEdit(sourceData, currentData) }
        />
        break;

      case "ReinstatementCategory":
        let rcData = null;

        if (formData.pkId && formData.pkId > 0) {
          rcData = asdData.reinstatementCatergories.find(x => x.pkId === formData.pkId);
          setLastUsedValue(rcData.pkId, "rc");
        } else if (formData.pkId && formData.pkId < 0) {
          rcData = {
            "pkId": formData.pkId,
            "changeType": "I",
            "proOrder": 0,
            "usrn": street_usrn,
            "custodianCode": operatorId,
            "seqNum": 0,
            "reinstatementAuthorityCode": operatorId,
            "streetReinstatementCategory": 1,
            "wholeRoad": true,
            "specificLocation": null,
            "state": 1,
            "entryDate": today,
            "startDate": today,
            "lastUpdateDate": today,
            "endDate": null,
            "wktGeometry": null,
            "lastUpdated": null,
            "isLocal": true
          }
          setLastUsedValue(null, null);
        }

        formComp = <ReinstatementCategoryForm Lookup_OSGReinstatementTypeCodes={Lookup_OSGReinstatementTypeCodes}
          Lookup_OSGSwaOrgRefs={Lookup_OSGSwaOrgRefs}
          data={rcData}
          editable={isEditable}
          dataIdx={formData.idx}
          dataLen={formData.len}
          onReturnClick={(srcData, currData) => FormReturn()}
          onSaveClick={(asdData) => SaveForm(formName, asdData)}
          onDeleteClick={(pkId) => DeleteForm(formName, pkId)}
          onWholeRoadChange={(isWholeRoad) => changeForm(formName, formData.pkId, formData.idx - 1, formData.len, isWholeRoad)}
          logEdit={(sourceData, currentData) => CheckForEdit(sourceData, currentData)}
        />
        break;

      case "SpecialDesignation":
        let sdData = null;
        let sdTitle = `Special Designation (${formData.idx} of ${formData.len})`;

        if (formData.pkId && formData.pkId > 0) {
          sdData = asdData.specialDesignations.find(x => x.pkId === formData.pkId);
          sdTitle = `${Lookup_OSGSpecialDesignationCodes.find(g => g.streetSpecialDesigCode === sdData.specialDesignationCode).streetSpecialDesigText} (${formData.idx} of ${formData.len})`;
          setLastUsedValue(sdData.pkId, "sd");
        } else if (formData.pkId && formData.pkId < 0) {
          sdData = {
            "pkId": formData.pkId,
            "changeType": "I",
            "proOrder": 0,
            "usrn": street_usrn,
            "custodianCode": operatorId,
            "seqNum": 0,
            "authorityCode": operatorId,
            "specialDesignationCode": 1,
            "wholeRoad": true,
            "specificLocation": null,
            "description": null,
            "state": 1,
            "entryDate": today,
            "startDate": today,
            "lastUpdateDate": today,
            "endDate": null,
            "wktGeometry": null,
            "lastUpdated": null,
            "isLocal": true
          }
          setLastUsedValue(null, null);
        }

        formComp = <SpecialDesignationForm Lookup_OSGSpecialDesignationCodes={Lookup_OSGSpecialDesignationCodes}
          Lookup_OSGSwaOrgRefs={Lookup_OSGSwaOrgRefs}
          data={sdData}
          editable={isEditable}
          title={sdTitle}
          dataIdx={formData.idx}
          dataLen={formData.len}
          onReturnClick={(srcData, currData) => FormReturn()}
          onSaveClick={(asdData) => SaveForm(formName, asdData)}
          onDeleteClick={(pkId) => DeleteForm(formName, pkId)}
          onWholeRoadChange={(isWholeRoad) => changeForm(formName, formData.pkId, formData.idx - 1, formData.len, isWholeRoad)}
          logEdit={(sourceData, currentData) => CheckForEdit(sourceData, currentData)}
        />
        break;

      default:

        formComp = <AAAssociatedStreetDataList
          asdData={asdData}
          Lookup_OSGRoadStatusCodes={Lookup_OSGRoadStatusCodes}
          Lookup_OSGReinstatementTypeCodes={Lookup_OSGReinstatementTypeCodes}
          Lookup_OSGSpecialDesignationCodes={Lookup_OSGSpecialDesignationCodes}
          Lookup_OSGSwaOrgRefs={Lookup_OSGSwaOrgRefs}
          editable={isEditable}
          handleDetailsClick={(formType, formData, dataIdx, dataLength, wholeRoad) => changeForm(formType, formData, dataIdx, dataLength, wholeRoad)}
          highlightItem={false}  //PD WI39308
          lastASD={getLastUsedValue()}
          showLastEdited={true}
        />
        break;
    }

    return formComp;
  }

  useEffect(() => {

    let isCancelled = false;

    async function SetUpApi() {
      if (!addMaintenenaceResponsibilityURL) {
        const addMRUrl = await GetAddMaintenanceResponsibilitiesURL(currentToken);
        setAddMaintenenaceResponsibilityURL(addMRUrl);
      }
      else {
        console.log("setupapi - addMaintenenaceResponsibilityURL not null ", addMaintenenaceResponsibilityURL);
      }

      if (!addReinstatementCategoryURL) {
        const addRCUrl = await GetAddReinstatementCategoriesURL(currentToken);
        setAddReinstatementCategoryURL(addRCUrl);
      }
      else {
        console.log("setupapi - addReinstatementCategoryURL not null ", addReinstatementCategoryURL);
      }

      if (!addSpecialDesignationURL) {
        const addSDUrl = await GetAddSpecialDesignationsURL(currentToken);
        setAddSpecialDesignationURL(addSDUrl);
      }
      else {
        console.log("setupapi - addSpecialDesignationURL not null ", addSpecialDesignationURL);
      }

      if (!updateMaintenenaceResponsibilityURL) {
        const updateMRUrl = await GetUpdateMaintenanceResponsibilitiesURL(currentToken);
        setUpdateMaintenenaceResponsibilityURL(updateMRUrl);
      }
      else {
        console.log("setupapi - updateMaintenenaceResponsibilityURL not null ", updateMaintenenaceResponsibilityURL);
      }

      if (!updateReinstatementCategoryURL) {
        const updateRCUrl = await GetUpdateReinstatementCategoriesURL(currentToken);
        setUpdateReinstatementCategoryURL(updateRCUrl);
      }
      else {
        console.log("setupapi - updateReinstatementCategoryURL not null ", updateReinstatementCategoryURL);
      }

      if (!updateSpecialDesignationURL) {
        const updateSDUrl = await GetUpdateSpecialDesignationsURL(currentToken);
        setUpdateSpecialDesignationURL(updateSDUrl);
      }
      else {
        console.log("setupapi - updateSpecialDesignationURL not null ", updateSpecialDesignationURL);
      }

      if (!deleteMaintenenaceResponsibilityURL) {
        const deleteMRUrl = await GetDeleteMaintenanceResponsibilitiesURL(currentToken);
        setDeleteMaintenenaceResponsibilityURL(deleteMRUrl);
      }
      else {
        console.log("setupapi - deleteMaintenenaceResponsibilityURL not null ", deleteMaintenenaceResponsibilityURL);
      }

      if (!deleteReinstatementCategoryURL) {
        const deleteRCUrl = await GetDeleteReinstatementCategoriesURL(currentToken);
        setDeleteReinstatementCategoryURL(deleteRCUrl);
      }
      else {
        console.log("setupapi - deleteReinstatementCategoryURL not null ", deleteReinstatementCategoryURL);
      }

      if (!deleteSpecialDesignationURL) {
        const deleteSDUrl = await GetDeleteSpecialDesignationsURL(currentToken);
        setDeleteSpecialDesignationURL(deleteSDUrl);
      }
      else {
        console.log("setupapi - deleteSpecialDesignationURL not null ", deleteSpecialDesignationURL);
      }
    }

    const LoadLookups = async () => {
      const Lookups = [
        { "url": await GetOSGRoadStatusCodes_URL(currentToken), "data": Lookup_OSGRoadStatusCodes, "id": "Lookup_OSGRoadStatusCodes" },
        { "url": await GetOSGReinstatementTypeCodes_URL(currentToken), "data": Lookup_OSGReinstatementTypeCodes, "id": "Lookup_OSGReinstatementTypeCodes" },
        { "url": await GetOSGSpecialDesignationCodes_URL(currentToken), "data": Lookup_OSGSpecialDesignationCodes, "id": "Lookup_OSGSpecialDesignationCodes" },
        { "url": await GetOSGSwaOrgRefs_URL(currentToken), "data": Lookup_OSGSwaOrgRefs, "id": "Lookup_OSGSwaOrgRefs" }
      ];

      Lookups.forEach(async (lookup) => {

        if (!isCancelled && !lookup.data) {
          const result = await fetchData(lookup.url)

          if (!isCancelled) {

            switch (lookup.id) {
              case "Lookup_OSGRoadStatusCodes": setLookup_OSGRoadStatusCodes(result); break;
              case "Lookup_OSGReinstatementTypeCodes": setLookup_OSGReinstatementTypeCodes(result); break;
              case "Lookup_OSGSpecialDesignationCodes": setLookup_OSGSpecialDesignationCodes(result); break;
                case "Lookup_OSGSwaOrgRefs": setLookup_OSGSwaOrgRefs(result); break;
                //no default
            }
          }
        }
      });
    }

    if (!isloaded) {
      SetUpApi();
      LoadLookups();
    }

    return () => {
      isCancelled = true;
    };

  }, [isloaded])

  const bIsloaded = !(!Lookup_OSGRoadStatusCodes || !Lookup_OSGReinstatementTypeCodes || !Lookup_OSGSpecialDesignationCodes || !Lookup_OSGSwaOrgRefs);

  if (bIsloaded && !isloaded) {
    setisLoaded(true);
  }

  return isloaded ? (renderForm(formData))
    : "Loading Associated Street Data";
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  formrow: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    alignItems: "center",
  },
}));


export default AssociatedStreetDataForm;

/* #region header */
/**************************************************************************************************
//
//  Description: Short Date Picker based on kendo date picker, refactored from asd forms
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   27.01.21 Peter Bryden               Initial Revision.
//    002   08.02.21 Sean Flook         WI38232 Set required.
//    003   03.03.21 Sean Flook         WI39198 Use Typography component to display read only dates.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Typography } from "@material-ui/core";
import moment from 'moment';

/* #endregion imports */

const ASDShortDatePicker = ({ dateValue, handleDateChangeEvent, isEditable, requiredField, validData }) => {
  const fmtDate = dateValue ? moment(dateValue).toDate() : null;

  return (isEditable) ?
    (
      <DatePicker
        format={"dd MMMM yyyy"}
        formatPlaceholder={{}}
        value={fmtDate}
        required={requiredField}
        valid={validData}
        onChange={handleDateChangeEvent}
      />
    )
    :
    (dateValue && (<Typography variant="body2" gutterBottom>{dateValue ? moment(dateValue).format("DD MMMM YYYY") : ""}</Typography>))
}

export default ASDShortDatePicker;

/**************************************************************************************************
//
//  Description: URL data about the api calls we need to make
//
//  Copyright:    © 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//#endregion Version 1.0.0.0 changes
//
**************************************************************************************************/

var currentConfig = {
    Api: "",
    securityApi: ""
  };
  
  const getConfigInfo = () => {
    if (
      !currentConfig ||
      !currentConfig.Api ||
      currentConfig.Api.length === 0
    ) {
  
      currentConfig = {
          Api: process.env.REACT_APP_API,
          securityApi: process.env.REACT_APP_SECURITY_API
      };
    }
  };
  
  
  export function GetApiSite(urlController) {
    getConfigInfo();
    const ApiUrl = `${currentConfig.Api}${urlController}`;
    return ApiUrl;
  }

  export function GetSecurityApiSite(urlController) {
    getConfigInfo();
    const SecurityApiUrl = `${currentConfig.securityApi}${urlController}`;
    return SecurityApiUrl;
  }
  
  
  
//#region header */
/**************************************************************************************************
//
//  Description:  street search combobox
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   21.12.20 Sean Flook         WI39031 Allow alternative lines in the drop down to be coloured.
//    003   06.01.21 Sean Flook         WI39031 Use correct attribute name.
//    004   13.01.21 Peter Bryden               pass maxresults parameter to street search to limit returned results
//    005   12.02.21 Sean Flook         WI39126 Do not search if we only have a single character.
//    006   18.02.21 Sean Flook         WI39159 If the search text is less than 2 characters clear the value variable.
//    007   05.03.21 Peter Bryden       WI39125 Make fetch calls asynchronous so as search is updated previous searches are cancelled
//    008   11.03.21 Peter Bryden               Add id to search for css purposes
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    009   27.05.21 Peter Bryden       WI39324 updated search so it can be re-used & re-sized in maintenance page
//#endregion Version 1.0.1.0 changes

//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */

import React, {useState, useEffect, useContext } from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { GetSearchStreetURL } from '../configuration/AAConfig';
import { UserContext } from "../contexts/userContext";

const apifetch = async (url, dataIfAborted, signal) => {
  try {
    const response = await fetch(url, {signal})

    const data = await response.json();
    return data;
  } catch (err) {
    if (err.name === "AbortError") {
      console.log("Request Aborted ");
      return dataIfAborted;
    }
    return err;
  }
};


const AAStreetSearch = ({ usrn, streetName, placeholder,handleChange, ctrlWidth }) => {

  const [data, setData] = useState([]);
  const [selectedUSRN, setSelectedUSRN] = useState(usrn);

  const [urlDetails, setUrlDetails] = useState(null);
  const [search, setSearch] = useState();

  const alternativeBackgroundColour1 = "white";
  const alternativeBackgroundColour2 = "lightcyan";

  const { currentUser } = useContext(UserContext);


  const GetApiSite = async () => {
    if (!urlDetails) {
      const url = await GetSearchStreetURL();
      setUrlDetails(url);
    }
  };

  const itemRender = (li, itemProps) => {
    const index = itemProps.index;
    let itemBackgroundColour = alternativeBackgroundColour1;
    if (index > 0 && (index % 2 !== 0))
      itemBackgroundColour = alternativeBackgroundColour2;
    const itemChildren = <span style={{ backgroundColor: itemBackgroundColour }}>{li.props.children}</span>;

    return React.cloneElement(li, li.props, itemChildren);
  }



  useEffect(() => {
    GetApiSite();

    if (streetName && streetName.length > 0)
      UpdateData(streetName);

  },[streetName]);



  useEffect(() => {

    const controller = new AbortController();

    if (urlDetails && search && search.length > 0) {

      const whilewaiting = [{ "usrn": 0, "descriptor": "Loading Data...." }]
      setData(whilewaiting);

      const signal = controller.signal;

      const url = `${urlDetails.url}?description=${search}&maxresults=200`;
      apifetch(url,whilewaiting,signal).then((res) => {
        setData(res);
      });
     }
     else {
        //console.log("no filterdata");
        setData([]);
     }

    return () => {
      setData([{ "usrn": 0, "descriptor": "Loading Data...." }]);
      controller.abort();
    };
  }, [search]);



  //when the component has selected an street from the dropdown
  const onChange = (event) => {
    const selected = event.target.value;

    if (selected)
      handleChange(selected.usrn, selected.descriptor);
  };


  //combobox change in typed edit field data
  const filterChange = (event) => {
    const value = event.filter.value;
    setSearch(value);

    if (value.length < 1) 
      setSelectedUSRN(null);
  };


  //Retrieve data from API Url passed into component
  const UpdateData = (filterData) => {

    if (urlDetails && filterData && filterData.length > 0) {

      setData([{ "usrn": 0, "descriptor": "Loading Data...." }] );

      fetch(`${urlDetails.url}?description=${filterData}&maxresults=200`)
        .then(res => (res.ok ? res : Promise.reject(res)))
        .then(res => res.json())
        .then(
          (result) => {
            setData(result);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            //this.setState({ error });
            setData([]);
          }
        );
    }
    else {
      console.log("no filterdata");
      setData([]);
    }
  }


  return currentUser ? (
    <ComboBox
      id="StreetSearch"
      value={data.find(el => el.usrn === selectedUSRN)}
      data={data}
      textField="descriptor"
      filterable={true}
      onFilterChange={filterChange}
      onChange={onChange}
      placeholder={placeholder}
      itemRender={itemRender}
      style={{ width: "100%" }}
    />
  ) :
  (
    <></>
  );

}

export default AAStreetSearch;
//#region header */
/**************************************************************************************************
//
//  Description:  Logo for top right hand corner of layout
//
//  Copyright:    � 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   06.01.21 Sean Flook         WI39037 Removed collapsed property as not being used.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
/* #endregion imports */

const AANavHeader = () => (
  <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={1}
  >
    <Grid item xs={12}>
      <Box mt={2}>
        <img
          src="/images/aligned_assets_logo.svg"
          alt="Aligned Assets"
          width="32"
          height="32"
        />
      </Box>
    </Grid>
  </Grid>
);

export default AANavHeader;

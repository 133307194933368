//#region header */
/**************************************************************************************************
//
//  Description: Gazetteer Page Form
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   19.01.21 Sean Flook                 Corrected spelling of Gazetteer.
//    003   25.01.21 Sean Flook         WI38232 Initial check-in for changes required for saving ASD records.
//    004   26.01.21 Sean Flook         WI38232 Changes required to limit which streets a user can edit.
//    005   10.02.21 Sean Flook         WI38232 Pass through the operator id.
//    006   10.02.21 Sean Flook         WI38232 Display a scrollbar if the data does not fit on the screen.
//    007   10.02.21 Sean Flook         WI38232 Changed to auto for the overflow-y.
//    008   12.02.21 Sean Flook         WI38232 Tweaked the height.
//    009   03.03.21 Sean Flook         WI39183 Added className to the div so styles can be set in the CSS.
//    010   09.03.21 Sean Flook         WI39240 Use the new ESU form.
//    011   21.03.21 Peter Bryden       WI39277 Set edit state null before  functionloss in confirmedit dialog
//    012   21.03.21 Peter Bryden       WI39243 Add in associatedstreetdatacontext to hold asd list open states
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    013   18.06.21 Peter Bryden       WI39514 Added last used context
//#endregion Version 1.0.0.0 changes
//--------------------------------------------------------------------------------------------------
//#endregion header */

/* #region imports */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import StreetForm from "./../../forms/StreetForm";
import AssociatedStreetDataForm from "./../../forms/AssociatedStreetDataForm";
import ESUForm from "./../../forms/ESUForm";

import { useConfirmation } from "./../../pages/GazetteerPageConfirmation";
import { AAAssociatedStreetDataListContextProvider, AAAssociatedStreetDataLastUsedContextProvider } from "../../contexts/AAAssociatedStreetDataListContext"

/* #endregion imports */

function TabPanel(props) {
  const { children, value, selectedValue, ...other } = props;
  return (
    <div
      className="gazetteer-page__tab-panel"
      role="tabpanel"
      hidden={value !== selectedValue}
      id={`aa-tabpanel-${value}`}
      aria-labelledby={`aa-tab-${value}`}
      {...other}
    >
      {value === selectedValue && <Box p={1}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function GazetteerTabProps(name) {
  return {
    id: `aa-tab-${name}`,
    "aria-controls": `aa-tabpanel-${name}`,
    value: `${name}`,
  };
}

const GazetteerTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 12,
    maxWidth: 60,
    marginRight: theme.spacing(2),
  },
  selected: { fontWeight: theme.typography.fontWeightBold },
}))((props) => <Tab {...props} />);


const GazetteerPageForm = ({ geometry, asdData, streetdata, isAsdEditable, operatorId, handleChange, handleSave, handleDelete }) => {

  const [selectedPanel, setSelectedPanel] = useState("street");
  const [currentEditState, setCurrentEditState] = useState({ "formChanged": false, "srcGeometry": null });

  const data = streetdata;
  const asdEditable = isAsdEditable;
  const idOperator = operatorId;
  const changeHandlerForMap = handleChange;

  const confirmDialog = useConfirmation();

  const handleTabChange = async (event, newValue) => {

    if (selectedPanel === "asd") {
      confirmEditLoss(() => showChangedPanel(newValue));
    }
    else {
      showChangedPanel(newValue)
    }
  };

  function showChangedPanel(newPanel) {
    handleChange("", null);
    setSelectedPanel(newPanel);
  }

  const updateCurrentEditState = (frmChanged, srcGeo) => setCurrentEditState({ "formChanged": frmChanged, "srcGeometry": srcGeo });

  function confirmEditLoss(functionOnLoss) {

    let hasEdited = false;

    if (currentEditState) {

      hasEdited = (currentEditState.formChanged);
      if (!hasEdited && (geometry.current && currentEditState.srcGeometry))
        hasEdited = !(currentEditState.srcGeometry === geometry.current);

      console.log("confirmEditLoss ", hasEdited, currentEditState, geometry.current);

      confirmDialog(hasEdited)
        .then(() => {
          setCurrentEditState(null);
          functionOnLoss();
        })
        .catch(() => {
        });
    }

    if (!hasEdited) {
      setCurrentEditState(null);
      functionOnLoss();
    }
  }

  return (
    <React.Fragment>
      <AAAssociatedStreetDataLastUsedContextProvider>
      <AAAssociatedStreetDataListContextProvider>
        <Tabs
          value={selectedPanel}
          onChange={handleTabChange}
          aria-label="Gazetteer Tabs"
          variant="scrollable"
        >
          <GazetteerTab label="Street" {...GazetteerTabProps("street")} />
          <GazetteerTab label="ESU" {...GazetteerTabProps("esu")} />
          <GazetteerTab label="ASD" {...GazetteerTabProps("asd")} />
        </Tabs>
        <TabPanel value={"street"} selectedValue={selectedPanel}>
          <StreetForm data={data} />
        </TabPanel>
        <TabPanel value={"esu"} selectedValue={selectedPanel}>
          <ESUForm data={data} changeHandler={changeHandlerForMap} />
        </TabPanel>
        <TabPanel value={"asd"} selectedValue={selectedPanel}>
          <AssociatedStreetDataForm asdData={asdData} usrn={data.usrn} editable={asdEditable} operatorId={idOperator} changeHandler={changeHandlerForMap} saveHandler={handleSave} deleteHandler={handleDelete} editCheckHandler={updateCurrentEditState} confirmEditLoss={confirmEditLoss} />
        </TabPanel>
        </AAAssociatedStreetDataListContextProvider>
        </AAAssociatedStreetDataLastUsedContextProvider>
    </React.Fragment>
  );
};

export default GazetteerPageForm; 
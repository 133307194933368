import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UserContext } from "./../contexts/userContext";
import { PostUserLogin, GetWhoAmIUrl } from "./../configuration/AAConfig_Security";
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
//import { FormRowStyle, FormInputStyle } from "../utils/AAStyles";
import { useTheme } from "@material-ui/styles";

LoginDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

function FormRowStyle(hasError) {
  const theme = useTheme();

  if (hasError)
    return {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      color: theme.palette.text.secondary,
      backgroundColor: "rgba(196, 5, 28, 0.1)",
      display: "flex",
      justifyContent: "flex-start",
    };
  else
    return {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      color: theme.palette.text.secondary,
      display: "flex",
      justifyContent: "flex-start",
    };
}

function FormInputStyle(hasError) {
  const theme = useTheme();

  if (hasError)
    return {
      backgroundColor: theme.palette.background.paper,
      "&$outlinedInputFocused": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#C4051C",
        // borderColor: `${red[500]} !important`,
      },
    };
  else
    return {
      backgroundColor: theme.palette.background.paper,
      "&$outlinedInputFocused": {
        borderColor: `${theme.palette.primary.main} !important`,
      },
      "&$notchedOutline": {
        borderWidth: "1px",
        borderColor: "#e1e1e1 !important",
      },
    };
}



function LoginDialog({ title, message }) {
  const theme = useTheme();

  const { showLogin, updateLoginDetails, updateCurrentUser, updateShowLogin } = useContext(UserContext);
  
  const [apiUrl, setApiUrl] = useState(null);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginError, setLoginError] = useState(null);
  
  const handleUsernameChangeEvent = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChangeEvent = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
    };

    const IsOperatorIdValid = (userInfo) => {

      if(!userInfo) return false;

      const extraInfo = userInfo.extraInformation;
      const operatorKeyName = "TR Operator Id";

      if (extraInfo) 
      {
          return extraInfo.some(x => {
              return (x.key === operatorKeyName);
          });
      }

      return false;
  };    

    const IsLoginValid = () => {
        const bUserNameEmpty = !username || username.length === 0;
        const bPasswordEmpty = !password || password.length === 0;

        //console.log(`bUserNameEmpty : ${bUserNameEmpty} [${username}], bPasswordEmpty : ${bPasswordEmpty} [${password}]`);

        if (bPasswordEmpty || bUserNameEmpty) return false;

        return true;
    }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

    const handleLoginClick = async () => {

        if (!IsLoginValid()) {
            setLoginError("One or more credentials is empty.");
            return;
        }

    const loginDetails = {
      email: username,
      password: password,
    };

    //console.log("[PJB] handleLoginClick - loginDetails", loginDetails, apiUrl);

    if (apiUrl) {
      const loginRes = await fetch(`${apiUrl.url}`, {
        cache: "no-cache",
        headers: apiUrl.headers,
        crossDomain: true,
        method: apiUrl.type,
        body: JSON.stringify(loginDetails),
      })
        .then((res) => (res.ok ? res : Promise.reject(res)))
        .then((res) => res.json())
        .then((result) => {
          // console.log("[SF] handleLoginClick - result", result);
          // userContext.onUserChange(result);
          // setShowDialog(false);
          return result;
        })
        .catch((error) => {
          // console.log("[SF] handleLoginClick - exception", e);
          // userContext.onUserChange(null);
          switch (error.status) {
            case 400:
              setLoginError(
                "You need to enter a valid username and password, please try again."
              );
              break;

            case 401:
              setLoginError("Unknown username or password, please try again.");
              break;

            default:
              setLoginError(
                "An unknown error occurred, please report to support."
              );
              // console.log("ERROR failed to log in", error);
              break;
          }
          setUsername("");
          setPassword("");
          // console.log("ERROR failed to log in", e);
          return null;
        });

      if (loginRes) {
        // console.log("[SF] handleLoginClick - loginRes", loginRes);
        const userUrl = await GetWhoAmIUrl(loginRes.token);

        if (userUrl) {
          // console.log("[SF] GetUserInformation", userUrl);
          const userInfo = await fetch(`${userUrl.url}`, {
            headers: userUrl.headers,
            crossDomain: true,
            method: "GET",
          })
            .then((res) => (res.ok ? res : Promise.reject(res)))
            .then((res) => res.json())
            .then(
              (result) => {
                // console.log("[SF] GetUserInfo", result);
                return result;
              },
              (error) => {
                console.log("ERROR Get user information", error);
                return null;
              }
            );

          if (userInfo) {

            const bOperatorValid = IsOperatorIdValid(userInfo);

            if (userInfo.active && !userInfo.isDeleted && bOperatorValid) {
                //const loggedInUser = { token: loginRes.token, ...userInfo };
                // console.log("[SF] loggedInUser", loggedInUser);
                //userContext.onUserChange(loggedInUser);
                updateLoginDetails(loginRes);
                updateCurrentUser(userInfo);
                //updateShowLogin(false);
            } else {
                
              updateCurrentUser(null);
              
              //userContext.onUserChange(null);
              if (!userInfo.active)
                setLoginError("You are not an active user on this system.");
              if(userInfo.isDeleted)
                setLoginError("This user has been deleted.");
              if(!bOperatorValid)   
                setLoginError("No operator Id.");
              
              updateLoginDetails(null);    
              setUsername("");
              setPassword("");
              //updateShowLogin(true);
            }
          } else {
              updateCurrentUser(null);
            //userContext.onUserChange(null);
            setLoginError("Unable to get user information.");
              //updateShowLogin(true);
          }
        } else {
            updateCurrentUser(null);
          //userContext.onUserChange(null);
          setLoginError("Unable to get user information URL.");
            //updateShowLogin(true);
        }
      } else {
          updateCurrentUser(null);
        //userContext.onUserChange(null);
        setLoginError("Unable to login with the supplied credentials.");
        setUsername("");
        setPassword("");
        //updateShowLogin(true);
      }
    } else console.log("Security apiUrl is null");
  };

    const handleCancelClick = () => {
        updateCurrentUser(null);
    //userContext.onUserChange(null);
    updateShowLogin(false);
  };

  useEffect(() => {
    async function SetUpApi() {
      const userUrl = await PostUserLogin();
      // console.log("[SF] SetUpApi", userUrl);
      setApiUrl(userUrl);
    }

    if (!apiUrl) SetUpApi();

    setLoginError(null);

    return () => {};
  }, [apiUrl]);

  return (
    <Dialog
      open={showLogin}
      aria-labelledby="confirm-edit-loss-dialog"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle
        id="confirm-edit-loss-dialog"
        sx={{ color: "#FFFFFF", backgroundColor: "#2A6EBB" }}
      >
        {title ? title : "Login"}
      </DialogTitle>
      <DialogContent sx={{ marginTop: theme.spacing(1) }}>
        {loginError ? (
          <Typography variant="body1" color="error">
            {loginError}
          </Typography>
        ) : (
          <Typography variant="body1">{message}</Typography>
        )}
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={FormRowStyle()}
        >
          <Grid item xs={3}>
            <Typography
              id="aa-text-label-username"
              variant="body2"
              color="initial"
              align="left"
            >
              Username*
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="aa-text-textfield-username"
              sx={FormInputStyle()}
              fullWidth
              required
              variant="outlined"
              margin="dense"
              size="small"
              value={username}
              onChange={handleUsernameChangeEvent}
              aria-labelledby="aa-text-label-username"
            />
          </Grid>
          <Grid item xs={3}>
            <Typography
              id="aa-text-label-password"
              variant="body2"
              color="initial"
              align="left"
            >
              Password*
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              id="aa-text-textfield-password"
              sx={FormInputStyle()}
              type={showPassword ? "text" : "password"}
              fullWidth
              required
              variant="outlined"
              margin="dense"
              size="small"
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordClick}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{
                        "&:hover": {
                          color: "#2A6EBB",
                        },
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handlePasswordChangeEvent}
              aria-labelledby="aa-text-label-password"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ marginBottom: theme.spacing(1) }}>
        <Button
          onClick={handleLoginClick}
          autoFocus
          variant="contained"
          sx={{
            color: "#FFFFFF",
            backgroundColor: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<ExitToAppIcon />}
        >
          Login
        </Button>
        <Button
          onClick={handleCancelClick}
          sx={{
            color: "#2A6EBB",
            "&:hover": {
              backgroundColor: "#00AEEF",
              color: "#FFFFFF",
            },
          }}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LoginDialog;

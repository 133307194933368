/**************************************************************************************************
//
//  Description:  HOC to pass on street name and usrn
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
//functional HOC with useState hook
import React from 'react';
/* #endregion imports */

function WithStreet(WrappedComponent) {
    return function ({ usrn, handleChange, streetName, ...props }) {

        props['usrn'] = usrn;
        props['setStreet'] = handleChange;
        props['streetName'] = streetName;

        return <WrappedComponent {...props} />;
    }
}

export default WithStreet;
import React from 'react';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Alert } from '@material-ui/lab';


const ErrorDialog = ({ isOpen, message, handleClick }) => {

  return (
    <Dialog open={isOpen} aria-labelledby="error-dialog">
      <DialogTitle id="error-dialog">Error</DialogTitle>
      <DialogContent>
        <Alert severity="error">{message}</Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color="primary">Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog
/**************************************************************************************************
//
//  Description: Display list of ASD items
//
//  Copyright:    © 2020 - 2021 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//    002   15.01.21 Sean Flook         WI39041 Added an Add button and associated events.
//    003   15.01.21 Sean Flook         WI39041 Correctly define the Spatial Designation Add onClick event.
//    004   26.01.21 Sean Flook         WI38232 Only display the add button if the user is allowed to create new records on this street.
//    005   29.01.21 Peter Bryden       WI38232 cleaned up code and handled save back to forms
//    006   02.02.21 Sean Flook         WI38232 Only allow editing of geometry if part of road selected.
//    007   03.03.21 Sean Flook         WI39198 Use Typography component to display the text.
//    008   09.03.21 Sean Flook         WI39166 Added tooltip to icons.
//    009   10.03.21 Sean Flook         WI39238 Added missing classNames to Special Designation list item.
//    009   11.03.21 Sean Flook         WI39198 Changed the special designation icon.
//    010   23.03.21 Peter Bryden       WI39243 Added in AAAssociatedStreetDataTieredListItem to show special designations
//#endregion Version 1.0.0.0 changes
//#region Version 1.0.1.0 changes
//    011   27.05.21 Peter Bryden       WI39324 Add in checkbox handler for use with maintenance page
//    012   04.06.21 Peter Bryden       WI39324 Move other variables to state variables
//    013   04.06.21 Peter Bryden       WI39324 Fix special destination bug
//    014   08.06.21 Peter Bryden       WI39324 Add in end date to data sent in listiten to identify if its closed
//    015   08.06.21 Paula Dormer       WI39308 Use state not enddate to work out if the ASD is closed
//    016   09.06.21 Paula Dormer       WI39308 Only highlight open ASD on closed streets.
//    017   18.06.21 Peter Bryden       WI39514 Add in functionality to show last asd viewed, edited or added
//#endregion Version 1.0.1.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import Typography from '@material-ui/core/Typography';

import AAAssociatedStreetDataListItem from './AAAssociatedStreetDataListItem'
import AAAssociatedStreetDataTieredListItem from './AAAssociatedStreetDataTieredListItem';

import { brown, yellow, pink } from "@material-ui/core/colors";
/* #endregion imports */


function GetMaxDateItem(itemArray, propertyCheck) {

  const dateArrayWithIndex = [...itemArray.map((x, i) => {
    if (x.hasOwnProperty(propertyCheck)) {
      const isDate = new Date(x[propertyCheck]) instanceof Date;
      if (isDate)
        return { idx: i, theDate: new Date(x[propertyCheck]) };
    }
    else {
      console.log("cannot find property ", propertyCheck, x);
    }

    return { idx: i, theDate: new Date(0) };
  })];

  const maxDate = (dateArrayWithIndex && dateArrayWithIndex.length > 0) ? dateArrayWithIndex.reduce((a, b) => (a.theDate > b.theDate ? a : b)) : null;
  return maxDate ? { item: itemArray[maxDate.idx], theDate: maxDate.theDate } : null;
}



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 450,
    backgroundColor: theme.palette.background.paper,
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested_1: {
    paddingLeft: theme.spacing(10),
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  brown: {
    color: theme.palette.getContrastText(brown[500]),
    backgroundColor: brown[500],
  },
  yellow: {
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
  }

}));

const AAAssociatedStreetDataList = (props) => {
  const classes = useStyles();

  const asdData = props.asdData;
  const isEditable = props.editable;
  const onItemSelect = props.handleDetailsClick;
  const highlightedItem = props.highlightItem;
  const lastASD = props.lastASD;
  const scrollToLastEdited = (!props.showLastEdited) ? false : props.showLastEdited;

  const onItemChecked = (asdType, checkedData) => {

    const newCheckedData = checkedData.map((i) => { return { "asdType": asdType, "id": i }; });
    props.handleChecked(asdType, newCheckedData);
  }

  const handleMaintenanceResponsibilitySelection = (pkId, dataIdx, dataLength, wholeRoad) => { if (onItemSelect) onItemSelect("MaintenanceResponsibility", pkId, dataIdx, dataLength, wholeRoad); }

  const handleReinstatementCategorySelection = (pkId, dataIdx, dataLength, wholeRoad) => { if (onItemSelect) onItemSelect("ReinstatementCategory", pkId, dataIdx, dataLength, wholeRoad); }

  const handleSpecialDesignationSelection = (pkId, dataIdx, dataLength, wholeRoad) => { if (onItemSelect) onItemSelect("SpecialDesignation", pkId, dataIdx, dataLength, wholeRoad); }

  const handleMaintenanceResponsibilityAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("MaintenanceResponsibility", -1, dataLength, newDataLength, true);
  }

  const handleReinstatementCategoryAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("ReinstatementCategory", -1, dataLength, newDataLength, true);
  }

  const handleSpecialDesignationAdd = (dataLength) => {
    const newDataLength = dataLength + 1;
    onItemSelect("SpecialDesignation", -1, dataLength, newDataLength, true);
  }

  const [Lookup_OSGRoadStatusCodes_data] = useState(props.Lookup_OSGRoadStatusCodes);
  const [Lookup_OSGReinstatementTypeCodes_data] = useState(props.Lookup_OSGReinstatementTypeCodes);
  const [Lookup_OSGSpecialDesignationCodes_data] = useState(props.Lookup_OSGSpecialDesignationCodes);
  const [Lookup_OSGSwaOrgRefs_data] = useState(props.Lookup_OSGSwaOrgRefs);

  const maintenanceResponsibilities = asdData.maintenanceResponsibilities;
  const reinstatementCatergories = asdData.reinstatementCatergories;
  const specialDesignations = asdData.specialDesignations;

  const [specialDesignationTypes, setSpecialDesignationTypes] = useState();
  const [lastEditedASD, setLastEditedASD] = useState(null);


  useEffect(() => {

    const bSetDesignationTypes = specialDesignations && Lookup_OSGSpecialDesignationCodes_data;                                                                                                                   

    if (bSetDesignationTypes) {
      var unique_specialDesignationTypes = [...new Set(specialDesignations.map(sd => sd.specialDesignationCode))];
      let newSpecialDesignationTypes = unique_specialDesignationTypes.map(d => { return ({ "id": d, "name": Lookup_OSGSpecialDesignationCodes_data.find(x => x.streetSpecialDesigCode === d).streetSpecialDesigText }); });
      newSpecialDesignationTypes.sort((a, b) => a.id - b.id);
      setSpecialDesignationTypes(newSpecialDesignationTypes);
    }

  }, [specialDesignations])


  useEffect(() => {

    if (scrollToLastEdited && !lastASD) {

      const addMinutes = (theDate, minutes) => { return new Date(theDate.getTime() + minutes * 60000); }

      const minEditTime = addMinutes(new Date(), -1)
      const dateFieldToCheck = "lastUpdated"
      const nullArrCheck = (arr) => { return arr ? arr : [] };

      const allASDs = [...nullArrCheck(specialDesignations), ...nullArrCheck(reinstatementCatergories), ...nullArrCheck(maintenanceResponsibilities)]
      const latest = GetMaxDateItem(allASDs, dateFieldToCheck);

      if (latest && latest.theDate > minEditTime) {
        setLastEditedASD(latest ? latest.item : null);
      }
    }

    if (lastASD) {
      if (lastASD.hasOwnProperty("type") && lastASD.hasOwnProperty("pkid")) {
        const { type, pkid } = lastASD;

        if (type === "sd") {
          setLastEditedASD(specialDesignations.find(x => x.pkId === pkid));
        }

        if (type === "rc") {
          setLastEditedASD(reinstatementCatergories.find(x => x.pkId === pkid));
        }

        if (type === "mr") {
          setLastEditedASD(maintenanceResponsibilities.find(x => x.pkId === pkid));
        }
			}
    }
  }, [lastASD])

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          <Typography variant="body1">Associated Street Data</Typography>
        </ListSubheader>
      }
      className={classes.root}
    >
      <AAAssociatedStreetDataListItem title="Maintenance Responsibility" type="Type 51" itemIcon="PeopleRoundedIcon" colour="brown"
        itemSelect={(pkId, dataIdx, dataLength, wholeRoad) => handleMaintenanceResponsibilitySelection(pkId, dataIdx, dataLength, wholeRoad)}
        itemAdd={(dataLength) => handleMaintenanceResponsibilityAdd(dataLength)}
        data={maintenanceResponsibilities && maintenanceResponsibilities.map((mr) => (
          { "isLastEdited": (mr === lastEditedASD), "id": mr.streetStatus, "displayText": `${Lookup_OSGRoadStatusCodes_data.find(g => g.roadStatusCode === mr.streetStatus).roadStatusText} - ${Lookup_OSGSwaOrgRefs_data.find(o => o.swaOrgRef === mr.maintainingAuthorityCode).swaOrgNameText}`, "pkId": mr.pkId, "wholeRoad": mr.wholeRoad, "state": mr.state }
        ))}
        handleChecked={(props.handleChecked) ? (checkedData) => onItemChecked(51, checkedData) : null}
        editable={isEditable}
        highlightItem={highlightedItem}
        lastEdited={maintenanceResponsibilities && maintenanceResponsibilities.find(x => x === lastEditedASD)}
      />

      <AAAssociatedStreetDataListItem title="Reinstatement Category" type="Type 52" itemIcon="BlurOnIcon" colour="pink"
        itemSelect={(pkId, dataIdx, dataLength, wholeRoad) => handleReinstatementCategorySelection(pkId, dataIdx, dataLength, wholeRoad)}
        itemAdd={(dataLength) => handleReinstatementCategoryAdd(dataLength)}
        data={reinstatementCatergories && reinstatementCatergories.map((rc) => (
           { "isLastEdited": (rc === lastEditedASD), "id": rc.streetReinstatementCategory, "displayText": `${Lookup_OSGReinstatementTypeCodes_data.find(g => g.streetReinstatementTypeCode === rc.streetReinstatementCategory).reinstatementTypeText} - ${Lookup_OSGSwaOrgRefs_data.find(o => o.swaOrgRef === rc.reinstatementAuthorityCode).swaOrgNameText}`, "pkId": rc.pkId, "wholeRoad": rc.wholeRoad, "state": rc.state }
        ))}
        handleChecked={(props.handleChecked) ? (checkedData) => onItemChecked(52, checkedData) : null}
        editable={isEditable}
        highlightItem={highlightedItem}
        lastEdited={reinstatementCatergories && reinstatementCatergories.find(x => x === lastEditedASD)} />


      <AAAssociatedStreetDataTieredListItem title="Special Designations" type="Type 53" colour="yellow"
        itemSelect={(pkId, dataIdx, dataLength, wholeRoad) => handleSpecialDesignationSelection(pkId, dataIdx, dataLength, wholeRoad)}
        itemAdd={() => handleSpecialDesignationAdd(specialDesignations ? specialDesignations.length : 0)}
        tiers={specialDesignationTypes}
        data={specialDesignations && specialDesignations.map((sd) => (
          { "isLastEdited": (sd === lastEditedASD), "tier_id": sd.specialDesignationCode, "id": sd.specialDesignationCode, "displayText": `${sd.specificLocation ?? ''} ${sd.description}`, "pkId": sd.pkId, "wholeRoad": sd.wholeRoad, "state": sd.state }
        ))}
        handleChecked={(props.handleChecked) ? (checkedData) => onItemChecked(53, checkedData) : null}
        editable={isEditable}
        highlightItem={highlightedItem}
        lastEdited={specialDesignations && specialDesignations.find(x => x === lastEditedASD)}
      />

    </List>
  );
};



export default AAAssociatedStreetDataList;

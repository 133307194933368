/**************************************************************************************************
//
//  Description: Navigation sidebar
//
//  Copyright:    © 2020 Aligned Assets Limited
//
//--------------------------------------------------------------------------------------------------
//
//  Modification History:
//
//  Version Date     Modifier            Issue# Description
//#region Version 1.0.0.0 changes
//    001   19.10.20 Peter Bryden               Initial Revision.
//#endregion Version 1.0.0.0 changes
//
//--------------------------------------------------------------------------------------------------
//#endregion */

/* #region imports */
import React, { Fragment, useContext } from "react";
import { UserAdminRoute } from "../PageRouting";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Tooltip, Grid, IconButton } from "@material-ui/core";
import { UserContext } from "../contexts/userContext";




/* #endregion imports */

function GetCurrentUserName(currentUser) {
  return currentUser
    ? `${currentUser.firstName} ${currentUser.lastName}`
    : null;
}


function AAUserAvatar(props) {

  const { logoff, currentUser, updateShowLogin } = useContext(UserContext);
  const handleItemClick = props.handleClick;

    return currentUser ?
        (
            <Fragment>
            <Grid item xs={12}>
                <IconButton
                    aria-label="profile"
                    onClick={() => {handleItemClick(UserAdminRoute)}}
                    size="medium"
                >
                    <Tooltip title={`${GetCurrentUserName(currentUser)}`} arrow placement="right">
                    <AccountCircleIcon fontSize="large" color="primary" />
                    </Tooltip>
                </IconButton>
            </Grid>
            <Grid item xs={12}>
            <IconButton aria-label="logout" onClick={logoff}>
              <Tooltip title={`LogOff ${GetCurrentUserName(currentUser)}`} arrow placement="right">
                <ExitToAppIcon
                  color="primary"
                  fontSize="large"
                />
              </Tooltip>
            </IconButton>
          </Grid>
          </Fragment>

     ) :
    (
    <Grid item xs={12}>
          <IconButton
              aria-label="profile"
              
        onClick={()=>{updateShowLogin(true)}}
        size="medium"
      >
        <Tooltip title="Login" arrow placement="right">
          <AccountCircleIcon fontSize="large" color="primary" />
        </Tooltip>
      </IconButton>
      </Grid>
  );
}

export default AAUserAvatar;


/*
const AAUserAvatar = (props) => {

  const { currentToken, currentUser, currentOperator } = useContext(UserContext);
  const handleItemClick = props.handleClick;

  const authenticatedView = (userName, profilePath, logoutPath) => {
    return (<Fragment>

      <Grid item xs={12}>
        <IconButton aria-label="profile" onClick={() => { handleItemClick(profilePath) }}>
          <Tooltip title={userName} arrow placement="right">
            <AccountCircleIcon
              color="primary"
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <IconButton aria-label="logout" onClick={() => { handleItemClick(logoutPath) }}>
          <Tooltip title="Logout" arrow placement="right">
            <ExitToAppIcon
              color="primary"
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
    </Fragment>);
  };

  const anonymousView = (loginPath) => {
    return (<Fragment>
      <Grid item xs={12}>
        <IconButton aria-label="profile" onClick={() => { handleItemClick(loginPath) }}>
          <Tooltip title="Login" arrow placement="right">
            <AccountCircleIcon
              color="primary"
              fontSize="large"
            />
          </Tooltip>
        </IconButton>
      </Grid>
    </Fragment>);
  };


}

export default AAUserAvatar;
*/